<template>
    <router-view />
</template>
<script>
import {useI18n} from "vue-i18n";
import AOS from 'aos';
import 'aos/dist/aos.css';
import AutoUpdater from './utils/AutoUpdater';

export default {
    data: () => ({
        interval: null,
        intervalHelthChecker: null,
        token: null
    }),
    setup() {
        const {t} = useI18n();
        return {t};
    },
    computed: {
        isUserLogged() {
            return this.$store.getters.isLoggedIn;
        }
    },
    mounted() {
        AOS.init();

        this.$store.dispatch('checkAuth');

        if(
            !String(window.location).includes('view-invoice')
            && !String(window.location).includes('pay-invoice')
            && !String(window.location).includes('api-documentation')
        ){
            if(this.isUserLogged){
                this.heartBeatChecker();
            } else {
                this.interval && clearInterval(this.interval);
            }
        }

        this.helthChecker();
    },
    methods: {
        heartBeatChecker() {
            this.interval = setInterval(() => {
                if(this.isUserLogged){
                    this.$store.dispatch('loadSettings');
                }else{
                    clearInterval(this.interval);
                }
            }, 5000);
        },
        helthChecker() {
            this.intervalHelthChecker = setInterval(() => {
                if(this.isUserLogged){
                    this.$store.dispatch('loadProfile');
                }else{                
                    return fetch(process.env.VUE_APP_API_PATH)
                        .then(response => {
                            // Update page after build
                            AutoUpdater(response.headers.get('x-app-hash'));

                            return response;
                        })
                        .catch(error => {
                            console.error('Fetch error:', error);
                        });
                }
            }, 10000);
        }
    },
    unmounted() {
        clearInterval(this.interval);
        clearInterval(this.intervalHelthChecker);
    }
};
</script>
<style></style>
