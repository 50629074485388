<template>
    <div class="card">
        <div class="card-image" v-if="$slots.image">
            <slot name="image"/>
        </div>
        <div class="card-header" v-if="$slots.header || title">
            <slot name="header">
                <h4 class="card-title">{{ title }}</h4>
                <p class="card-category" v-if="subTitle">{{ subTitle }}</p>
            </slot>
        </div>
        <div class="card-body" v-if="$slots.default" :style="bodyStyle">
            <slot />
        </div>
        <slot name="raw-content" />
        <div class="card-footer" v-if="$slots.footer">
            <slot name="footer" />
        </div>
    </div>
</template>
<script>
export default {
    name: "card-template",
    props: {
        title: String,
        subTitle: String,
        styles: {
            type: Object,
            default: null
        }
    },
    computed:{
        bodyStyle() {
            return this.styles?.body || '';
        }
    }
};
</script>
<style></style>
