
import GlobalComponents from "./globalComponents";
// import GlobalDirectives from "./globalDirectives";
import "es6-promise/auto";

export default {
    install(Vue) {
        Vue.use(GlobalComponents);
        // Vue.use(GlobalDirectives);
        //  Vue.use(Notify);
    },
};
