/**
 * Import packages
 */
import { createApp } from 'vue'
import Toaster from '@meforma/vue-toaster';
import Modal from "vue-bs-modal";
import GoogleSignInPlugin from "vue3-google-signin";

/**
 * Import
 */
import router from './router'
import store from './store'

/**
 * Import components
 */
import App from './App'
import PaperDashboard from "./plugins/paperDashboard";
import i18n from './translation';

/**
 * Import Font Awesome Icon
 */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDoubleDown,
    faAngleDoubleUp,
    faCopy,
    faXmark,
    faTrashCan,
    faLink,
    faGauge,
    faFileInvoice,
    faFileInvoiceDollar,
    faUnlock,
    faPlug,
    faMoneyBillTransfer,
    faWallet,
    faGear,
    faMoneyBillTrendUp,
    faPuzzlePiece,
    faSection,
    faEdit,
    faEye,
    faChevronUp,
    faChevronDown,
    faFileExcel,
    faCircleDollarToSlot
} from '@fortawesome/free-solid-svg-icons';

import {
    faUser as farFaUser,
    faBell as farFaBell,
    faEnvelope as farFaEnvelope,
    faCalendarDays as farFaCalendarDays,
    faIdBadge as farFaIdBadge
} from '@fortawesome/free-regular-svg-icons';


library.add(
    faAngleDoubleDown,
    faAngleDoubleUp,
    faTrashCan,
    faCopy,
    faXmark,
    faLink,
    faGauge,
    faFileInvoice,
    faFileInvoiceDollar,
    faUnlock,
    faPlug,
    faMoneyBillTransfer,
    faWallet,
    faGear,
    faMoneyBillTrendUp,
    faPuzzlePiece,
    faSection,
    faEdit,
    faEye,
    faChevronUp,
    faChevronDown,
    faFileExcel,
    faCircleDollarToSlot,
    farFaUser,
    farFaBell,
    farFaEnvelope,
    farFaCalendarDays,
    farFaIdBadge
);

/**
 * Create App
 */
const app = createApp(App);

app.config.globalProperties.COMPANY_NAME = process.env.VUE_APP_COMPANY_NAME;
app.config.globalProperties.COMPANY_URL = process.env.VUE_APP_COMPANY_URL;
app.config.globalProperties.API_URL = process.env.VUE_APP_API_PATH;
app.config.globalProperties.ACTIVE_CURRENCIES = process.env.VUE_APP_ACTIVE_CURRENCIES;

app.config.globalProperties.$requestInterval = 15000;

app.config.errorHandler = (error) => {
    console.error('An error', error);
};

app.directive('focus', {
    mounted(el) {
        el.focus()
    }
})

app.component('fa-icon', FontAwesomeIcon)
    .use(store)
    .use(Modal)
    .use(Toaster, {
        position: 'top',
        duration: 2000,
        queue: true
    })
    .use(GoogleSignInPlugin, {
        clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    })
    .use(router)
    .use(i18n)
    .use(PaperDashboard)
    .mount('#app');
