import axios from '../../utils/api';
import i18n from '@/translation';

const { t } = i18n.global;

export default {
    state: {
        callbacks: [],
    },
    getters: {
        getCallbacksList: (state) => state.callbacks,
    },
    mutations: {
        SET_URLS_LIST(state, data) {
            state.callbacks = data;
        },
    },
    actions: {
        loadCallbacks(context) {
            // Preparing url params
            const params = {
                // page,
                // perPage,
                // sorting
            };

            // Do get data
            return axios.get('/invoices/callbacks/get-list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        // Transform items on push to table data
                        let list = [];
                        for (const [key] of Object.entries(data.data.list)) {
                            const item = data.data.list[key];
                            item.status = t(`dashboard.callbacks.statuses.${item.status}`)

                            list.push(item)
                        }

                        context.commit('SET_URLS_LIST', list);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async createCallback(context, data = {}) {
            // Do send data
            return await axios.post('/invoices/callbacks/create', data)
                .then((response) => {
                    if (response.data.status && response.data.data) {
                        //
                    }

                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async deleteCallback(context, data = {}){
            // Do send data
            return await axios.post('/invoices/callbacks/delete', data)
                .then((response) => {
                    //if (!response.data.status) {}
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    modules: {}
};
