const from_int = 10 ** 8;

export function formatNumCurrency(number, decimal = 5, zeroCut = 2) {
    const numberParts = number.toString().split('.');

    const floatingValue = (numberParts[1] || '').replace(/0+$/, '').slice(0, decimal);

    return floatingValue ? `${numberParts[0]}.${floatingValue}` : numberParts[0] + (zeroCut > 0 ? '.' + '0'.repeat(zeroCut) : '');
}

/**
 * Format Exponential Number
 *
 * @param input
 * @returns {*|string}
 */
export function formatExponentialNumber(input) {
    const data = String(input).split(/[eE]/);
    if (data.length === 1) return data[0];

    let z = '',
        sign = input < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;

    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        return z + str.replace(/^-/, '');
    }

    mag -= str.length;
    while (mag--) z += '0';

    return str + z;
}

/**
 * Split number by digit group
 */
export function numberDelimiter(number, group = 3) {
    const parts = String(number).replace(/\s/g, '').trim().split('.');

    const integerValue = parts[0]
        .split("")
        .reverse()
        .map((symbol, index) => {
            if (index % group === 0 && index !== 0) {
                return symbol + ' ';
            } else {
                return symbol;
            }
        })
        .reverse()
        .join("");

    return integerValue + (parts[1] ? '.' + parts[1] : '');
}

export function decimalFromInt(_num) {
    let num = +_num / from_int;

    if (Math.abs(num) < 1.0) {
        return (+_num / from_int).toFixed(9);
    } else {
        return formatExponentialNumber(num)
    }
}

export const formattedNumFromInt = (value, decimals = 5, cut = 2) => formatNumCurrency(decimalFromInt(value), decimals, cut);

/**
 * Format display date
 *
 * @param time
 * @returns {string}
 */
export function formatDate(time) {
    const date = new Date(time * 1000);

    return String(date.getDate()).padStart(2, '0') + "/" + String((date.getMonth() + 1)).padStart(2, '0') + "/" + date.getFullYear() + " " + String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0') + ":" + String(date.getSeconds()).padStart(2, '0');
}
