import axios from '../../utils/api';

export default {
    state: {
        isLoggedIn: false,
    },
    getters: {
        isLoggedIn: (state) => state.isLoggedIn,
    },
    mutations: {
        SET_LOGGED_STATUS(state, data) {
            state.isLoggedIn = data;
        },
    },
    actions: {
        async doLogout(context) {
            // Do logout
            const response = await axios.get('/invoices/auth/logout', {
                headers: {
                    'login-token': localStorage.getItem('token'),
                    'X-Auth-Sign': localStorage.getItem('secret'),
                }
            });

            // Clear local storage
            const lang = localStorage.getItem('lang');
            localStorage.clear();
            localStorage.setItem('lang', lang);

            context.commit('SET_LOGGED_STATUS', false);

            // Return response
            return response.data;
        },
        doLogin(context) {
            context.commit('SET_LOGGED_STATUS', true);
        },
        checkAuth(context){
            const TK = 'token';

            const getToken = () => localStorage.getItem(TK);

            context.commit('SET_LOGGED_STATUS', getToken() !== null);
        }
    },
    modules: {}
};