import axios from '../../utils/api';

export default {
    state: {
        formsData: {
            customer_legal: null,
            representative: null,
            incorporation_legal: null,
            residence_legal: null,
            general_info_legal: null,
            shareholder_natural: null,
            shareholder_legal: null,
            ubo: null,
            company_business: null,
            customer_aml_subject: null,
        }
    },
    getters: {
        getAllFormsData: (state) => state.formsData,
    },
    mutations: {
        SET_ALL_FORMS_DATA(state, data) {
            console.log(data);
            state.formsData = data;
        },
        SAVE_FORM(state, { form_name, data }) {
            state.formsData = {
                ...state,
                [form_name]: data
            };
        },
    },
    actions: {
        loadAllFormData(context) {
            // Do send data
            return axios.get('/invoices/kyc/questionnaire')
                .then((response) => {
                    console.log(response.data.data.data);
                    context.commit('SET_ALL_FORMS_DATA', response.data.data.data);

                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async saveFormData(context, { form_name, form_data }) {
            const new_forms_data = {
                ...context.getters.getAllFormsData,
                [form_name]: { ...form_data }
            };

            // Do send data
            return axios.post('/invoices/kyc/questionnaire-save', {
                ...new_forms_data
            })
                .then((response) => {
                    if (response.data.status && response.data.data) {
                        context.commit('SET_ALL_FORMS_DATA', response.data.data.data);
                    }

                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    modules: {}
};

export const formNames = {
    customer_legal: 'customer_legal',
    representative: 'representative',
    incorporation_legal: 'incorporation_legal',
    residence_legal: 'residence_legal',
    general_info_legal: 'general_info_legal',
    shareholder_natural: 'shareholder_natural',
    shareholder_legal: 'shareholder_legal',
    ubo: 'ubo',
    company_business: 'company_business',
    customer_aml_subject: 'customer_aml_subject',
}
