import axios from "@/utils/api";

export default {
    state: {
        list: [],
        isKeysExist: false
    },
    getters: {
        getApiKeysList: (state) => state.list,
        getIsKeysExist: (state) => state.isKeysExist,
    },
    mutations: {
        SET_API_KEYS_LIST(state, data) {
            state.list = data;

            state.isKeysExist = Object.keys(data).length > 0;
        },
        ADD_API_KEY(state, data) {
            state.list.push(data);

            state.isKeysExist = true;
        },
    },
    actions: {
        loadApiKeys(context) {
            // Preparing url params
            const params = {
                // page,
                // perPage,
                // sorting
            };

            // Do get data
            axios.get('/invoices/api-keys/get-list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        const keys = [];
                        for(const i in data.data.list){
                            keys.push(data.data.list[i]);
                        }

                        context.commit('SET_API_KEYS_LIST', keys);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async createApiKey(context, data = {}) {
            // Do send data
            return await axios.post('/invoices/api-keys/create', data)
                .then((response) => {
                    if (response.data.status && response.data.data) {
                        context.commit('ADD_API_KEY', response.data.data.api_key)
                    }

                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async deleteKey(context, data = {}) {
            // Do send data
            return await axios.post('/invoices/api-keys/delete', data)
                .then((response) => {
                    //if (!response.data.status) {}
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    modules: {}
};
