export default {
    header: {
        login: "लॉगिन",
        signUp: "साइन अप"
    },
    login: {
        h2: "खाते में लॉगिन करें",
        placeholder: {
            email: "ईमेल",
            password: "पासवर्ड",
            code_email: "ईमेल से कोड दर्ज करें",
            code_google: "Google प्रमाणक से कोड दर्ज करें"
        },
        forgot: "पासवर्ड भूल गए?",
        signUp: {
            text: "सदस्य नहीं हैं?",
            link: "साइन अप करें"
        },
        submit: "लॉगिन",
        validation: {
            req: "यह फ़ील्ड आवश्यक है",
            email: "यह फ़ील्ड एक वैध ईमेल होना चाहिए",
            password: "यह फ़ील्ड एक वैध पासवर्ड होना चाहिए (a-z, A-Z, 0-9)",
            code: "यह फ़ील्ड एक 4-अंक कोड होना चाहिए।"
        },
        errors: {
            check: "फॉर्म फ़ील्ड की जाँच करें"
        }
    },

    twoFaLinkActivation: {
        title: "दो-कारक प्रमाणीकरण",
        text: "कृपया प्रतीक्षा करें..."
    },

    register: {
        h2: "एक खाता बनाएं",

        placeholder: {
            email: "ईमेल",
            phone: "फ़ोन",
            password_repeat: "पासवर्ड दोहराएं",
            password: "पासवर्ड"
        },

        login: {
            text: "पहले से ही सदस्य हैं?",
            link: "लॉगिन"
        },

        submit: "खाता बनाएं",

        validation: {
            req: "यह फ़ील्ड आवश्यक है",
            email: "यह फ़ील्ड एक वैध ईमेल होना चाहिए",
            phone: "यह फ़ील्ड एक वैध फ़ोन होना चाहिए",
            password: "यह फ़ील्ड एक वैध पासवर्ड होना चाहिए (a-z, A-Z, 0-9)"
        },

        confirmation: {
            h1: "अभी तक तैयार",
            p: {
                0: "कृपया अपने ईमेल में सक्रियण लिंक पर क्लिक करें",
                1: "पंजीकरण की पुष्टि करने के लिए।",
            },
            link: "लॉगिन पर जाएं"
        },

        errors: {
            check: "फ़ॉर्म फ़ील्ड की जाँच करें"
        }
    },

    home: {
        promoSection: {
            h1: "बिटकॉइन भुगतान प्रसंस्करण गेटवे और बिजनेस क्रिप्टो वॉलेट",
            descLeft: {
                u: "बिटकॉइन में भुगतान स्वीकार करें",
                text: "अग्रणी क्रिप्टो भुगतान गेटवे के साथ इथेरियम, यूएसडीटी और अन्य डिजिटल मुद्राओं में भुगतान स्वीकार करें।"
            },
            descRight: "एक सुरक्षा अंकेक्षित क्रिप्टो वॉलेट के साथ क्रिप्टो में प्राप्त करें, भेजें, संग्रह करें, विनिमय करें और भुगतान करें।"
        },

        h2: "व्यवसाय के लिए सभी क्रिप्टो सेवाएं एक ही जगह पर",

        servicesSection: {
            0: {
                title: "भुगतान गेटवे",
                desc: "हमारी क्रिप्टो भुगतान प्रणाली किसी भी ऑनलाइन व्यवसाय की मदद करती है",
                u: "क्रिप्टो भुगतान स्वीकार करें",
                desc2: "और उनके चयनित राष्ट्रीय मुद्रा में तुरंत एक समान राशि प्राप्त करें या इसे रखें और उनके साथी और सहयोगी के साथ क्रिप्टो से भुगतान करें।"
            },

            1: {
                title: "क्रिप्टो SaaS समाधान",
                desc: "यह अपने ब्रांड के तहत व्यापारियों के लिए एक क्रिप्टो भुगतान सिस्टम या क्रिप्टोकरेंसी सेवा बनाने का एक लागत-प्रभावी अवसर है।"
            },

            2: {
                title: "एक्सचेंज",
                title2: "ओटीसी डेस्क",
                desc: "व्यापार बीटीसी, ईथी, यूएसडीटी, एक्सआरपी और 20 से अधिक अन्य क्रिप्टोकरेंसी का बेहतरीन दरों पर देशीय मुद्राओं में बदल सकते हैं और कोई भी छिपी शुल्क के साथ। फिएट ऑन और ऑफ रैंप समर्थित हैं।",
                desc2: "ओटीसी एक्सचेंज ऑपरेशन 1 लाख यूरो से अधिक के लिए उपलब्ध है।"
            },

            h3: "जानें कि हमारे समाधान आपके व्यवसाय की वृद्धि कैसे करते हैं",

            3: {
                title: "हॉट वॉलेट (कस्टडी)",
                desc: "क्रिप्टोकरेंसी हॉट वॉलेट सिस्टम उन व्यवसायों के लिए लक्षित है जिनका मॉडल डिजिटल एसेट (डिजिटल एसेट को स्वीकार, स्टोर और विदाई करने के लिए एक स्केलेबल और सुरक्षित वातावरण की आवश्यकता होती है) प्रबंधन के लिए होता है।"
            },
        },

        h3: {
            0: "व्यक्तिगत दृष्टिकोण",
            1: "बिटकॉइन को अपनाने के लिए",
            manage: "अपने डिजिटल एसेट को एक सुरक्षित ऑडिट की गई क्रिप्टो वॉलेट एप में प्रबंधित करें।"
        },

        wallet: {
            title: "क्रिप्टो वॉलेट",
            desc: {
                0: "15+ क्रिप्टोकरेंसियों को 5 विभिन्न ब्लॉकचेन पर प्राप्त, स्टोर और भेजें। सभी समर्थित डिजिटल एसेट को 16 फिएट मुद्राओं में सर्वोत्तम दरों पर एक्सचेंज करें।",
                1: "बैंक कार्ड के साथ बिटकॉइन और अन्य क्रिप्टो खरीदना आ रहा है।"
            }
        },
    },

    dashboard: {
        sidebar: {
            title: "मर्चेंट डैशबोर्ड",

            links: {
                dashboard: "डैशबोर्ड",
                profile: "प्रोफाइल",
                templates: "इनवॉइस टेम्पलेट्स",
                invoices: "चालान",
                addresses: "पते",
                wallets: "वॉलेट / निकास",
                withdrawRequests: "निकास अनुरोध",
                transactions: "लेनदेन",
                callbacks: "वेबहुक / कॉलबैक",
                api: "एपीआई प्रबंधन",
                logout: "लॉग आउट",
            },
        },

        main: {
            cards: {
                totalAvailableBalance: {
                    title: "कुल उपलब्ध शेष राशि",
                    footerText: "सभी समय"
                },
                revenue: {
                    title: "राजस्व",
                    footerLinks: {
                        0: "सभी समय",
                        1: "महीना",
                        2: "सप्ताह",
                        3: "आज",
                    }
                }
            }
        },

        profile: {
            title: "प्रोफाइल",
            subtitle: "प्रोफाइल",

            info: {
                title: "उपयोगकर्ता जानकारी",
                email: "ईमेल"
            },

            lang: {
                title: "भाषा",
                change: "भाषा बदलें"
            },

            expirations: {
                invoice: {
                    title: "समय सीमा",
                    change: "चालान समय सीमा",
                },

                invoiceAddress: {
                    title: "चालान पता समय सीमा",
                    hours: "घंटे"
                },
                minutes: "मिनट",

                info: "यदि आपके ग्राहक चालान समय सीमा के बाद भुगतान करते हैं और यदि एक्सचेंज दर घट गई है तो भुगतान एक्सचेंज नहीं किया जाएगा और आप अपने ग्राहक को वापस कर सकते हैं। यदि एक्सचेंज दर बढ़ गई है तो भुगतान एक्सचेंज किया जाएगा।",

                save: "सेटिंग्स सहेजें",

                error: "चालान समय सीमा चालान पता समय सीमा से कम या बराबर होना चाहिए"
            },
        },

        routeNames: {
            dashboard: "डैशबोर्ड",
            ProfilePage: "प्रोफ़ाइल",
            InvoiceTemplatesList: "चालान टेम्पलेट सूची",
            InvoicesForm: "चालान फ़ॉर्म",
            InvoicesList: "चालान सूची",
            InvoiceTemplatesCreateForm: "चालान टेम्पलेट फ़ॉर्म",
            InvoiceTemplatesEditForm: "चालान टेम्पलेट फ़ॉर्म",
            AddressesList: "पता सूची",
            WalletsList: "वॉलेट सूची",
            WithdrawRequest: "निकास अनुरोध",
            WithdrawRequestConfirm: "निकासी अनुरोध की पुष्टि",
            WithdrawTransactions: "निकास लेनदेन",
            TransactionsList: "लेनदेन सूची",
            ApiManagement: "API प्रबंधन",
            CallbacksList: "कॉलबैक सूची",
        },

        templates: {
            table: {
                0: "आईडी",
                1: "टेम्पलेट नाम",
                2: "मुद्रा",
                3: "राशि",
                4: "स्थिति",
                5: "कार्रवाई",
            },

            statuses: {
                0: "बनाया गया",
                1: "जमा के लिए प्रतीक्षा करें",
                2: "रद्द किया गया",
                3: "पूर्ण किया गया"
            },

            noRecords: "कोई रिकॉर्ड नहीं",

            title: "चालान टेम्पलेट सूची",
            subtitle: "चालान टेम्पलेट सूची",

            createInvoiceTemplate: "चालान टेम्पलेट बनाएं",

            cardTemplate: {
                0: "लेनदेन",
                1: "एम्बेड कोड",
                2: "लिंक",
                3: "हटाएं",
            },

            copy: {
                0: "टेम्पलेट चालान के लिए लिंक",
                1: "क्लिपबोर्ड पर कॉपी किया गया था",
                error: "पाठ कॉपी करने में त्रुटि हुई",
            },

            invoiceWidget: {
                title: "भुगतान",
                prodPurchase: "उत्पाद खरीद",
                btnSuccess: "भुगतान पर जाएं",
                label: {
                    email: "कृपया अपना ईमेल पता दर्ज करें",
                    choose: "भुगतान करने के लिए सिक्का चुनें"
                },
                amount: "भुगतान राशि"
            },

            widgetModal: {
                title: "इनवॉइस विजेट कोड",

                copy: {
                    success: "विजेट कोड क्लिपबोर्ड पर कॉपी किया गया था",
                    error: "टेक्स्ट कॉपी करते समय त्रुटि"
                }
            }
        },

        invoiceTemplatesForm: {
            title: "चालान टेम्पलेट बनाएं",

            label: {
                name: "चालान टेम्पलेट का नाम",
                convert: "भुगतान को कनवर्ट करें",
                price: "मूल्य",
                currency: "कॉइन में भुगतान प्राप्त करें",
                amount: "अनुरोधित राशि"
            },

            placeholder: {
                name: "टेम्पलेट नाम",
                price: "मूल्य",
                currency: "सभी कॉइन स्वीकार करें",
                amount: "अनुरोधित राशि"
            },

            success: {
                0: "चालान टेम्पलेट के लिए लिंक",
                1: "क्लिपबोर्ड पर कॉपी हो गया",
            },

            submit: "सहेजें और चालान के लिंक को कॉपी करें"
        },

        invoicesList: {
            title: "चालान सूची",
            subtitle: "चालान सूची",

            table: {
                0: "आईडी",
                1: "करेंसी",
                2: "चालान राशि",
                3: "स्थिति",
                4: "तिथि बनाई गई",
                5: "क्रियाएँ",
            },

            link: "लिंक",

            copy: {
                0: "चालान के लिए लिंक",
                1: "क्लिपबोर्ड पर कॉपी हो गया",
            },

            createInvoice: "चालान बनाएं"
        },

        invoicesForm: {
            title: "चालान बनाएं",

            label: {
                currency: "मुद्रा",
                amount: "मांग की गई राशि"
            },

            placeholder: {
                currency: "मुद्रा चुनें",
                amount: "मांग की गई राशि"
            },

            success: {
                0: "चालान बनाया गया था और चालान के लिए लिंक",
                1: "क्लिपबोर्ड पर कॉपी किया गया था",
            },

            submit: "सहेजें और चालान के लिए लिंक कॉपी करें"
        },

        addresses: {
            statuses: {
                0: "अक्षम",
                1: "सक्रिय",
                2: "Deprecated"
            },

            list: {
                title: "पतों की सूची",
                subtitle: "पतों की सूची",
                create: "पता बनाएं",
                edit: "संपादित करें",
                delete: "हटाएं",

                table: {
                    0: "आईडी",
                    1: "नाम",
                    2: "मुद्रा",
                    3: "कनवर्ट करें",
                    4: "न्यूनतम रिफिल",
                    5: "पता",
                    6: "स्थिति",
                    7: "क्रिया"
                }
            },

            form: {
                title: "पता बनाएं",
                label: {
                    label: "नाम",
                    currency: "मुद्रा",
                    convert_to: "कन्वर्ट करें:",
                    network_type: "नेटवर्क प्रकार"
                },
                placeholder: {
                    label: "नाम",
                    currency: "मुद्रा",
                    convert_to: "कनवर्ट मत करो",
                    network_type: "नेटवर्क प्रकार"
                },
                submit: "पता सहेजें"
            }
        },

        wallets: {
            title: "वॉलेट शेष राशि सूची",
            subtitle: "वॉलेट शेष राशि सूची",

            tableColumns: {
                0: "मुद्रा",
                1: "कुल शेष",
                2: "उपलब्ध शेष",
                3: "निकासी",
                4: "Swap"
            },

            withdraw: "निकासी",
            swap: "Swap"
        },

        walletsRequest: {
            title: "निकासी अनुरोध बनाएं",
            label: {
                networkType: "नेटवर्क प्रकार",
                address: "पता",
                amount: "राशि",
                iban: "IBAN"
            },
            placeholder: {
                networkType: "नेटवर्क प्रकार",
                address: "पता",
                amount: "राशि",
                iban: "IBAN"
            },
            submit: "निकासी",

            detailTitle: 'Withdrawal Detail',

            confirmCode: {
                title: "निकासी अनुरोध की पुष्टि",
                text: "कृपया निकासी की पुष्टि करें",
                labelEmail: "अपना ईमेल जांचें और कोड दर्ज करें",
                labelEmailSub: 'The code is valid for 5 minutes',
                labelGoogle: "Google प्रमाणक का उपयोग करके कोड दर्ज करें",
                placeholderEmail: 'मेल से कोड दर्ज करें',
                placeholderGoogle: "Google प्रमाणक से कोड दर्ज करें"
            },
        },

        walletsRequestsList: {
            title: "विथड्रॉ अनुरोध सूची",
            subtitle: "विथड्रॉ अनुरोध सूची",
            tableColumns: {
                0: "आईडी",
                1: "राशि",
                2: "मुद्रा",
                3: "कमीशन",
                4: "पता",
                5: "स्थिति",
                6: "तिथि",
                7: "Network"
            },

            statuses: {
                0: 'बनाया गया',
                1: 'भेज दिया गया',
                2: 'प्रतीक्षा कर रहा है',
                3: 'समीक्षा के तहत',
                4: 'प्रसंस्करण',
                5: 'पुष्टिकरण',
                6: 'हटाया गया',
                7: 'रद्द किया गया',
                11: 'निकाल रहा है',
                12: 'निकास प्रक्रिया बंद'
            }
        },

        transactionsList: {
            title: "लेनदेन सूची",
            subtitle: "लेनदेन सूची",
            tableColumns: {
                id: "पहचान",
                txid: "Hash",
                invoice_id: "चालान आईडी",
                direction: "Direction",
                amount_paid: "भुगतान की गई राशि",
                fee: "कमीशन",
                amount_converted: "में कन्वर्ट किया गया",
                convert_rate: "कन्वर्ट दर",
                item_name: "आइटम का नाम",
                address_name: "पते का नाम",
                address_deposit: "पते का जमा",
                user_email: "ग्राहक ईमेल",
                time_created: "तिथि",
                address_from: "पते से",
                action: "क्रिया",
                note: "Comment"
            },
            noteModal: {
                title: 'Transaction Comment',
                submit: 'Save Note'
            }
        },

        invoices: {
            statuses: {
                0: "बनाया गया",
                1: "जमा करने के लिए प्रतीक्षा करें",
                2: "समाप्त",
                3: "पूर्ण हुआ",
                4: "समाप्त",
                5: "पता समाप्त",
                partiallyPaid: 'Partially Paid'
            }
        },

        callbacks: {
            statuses: {
                0: 'अक्षम',
                1: 'सक्रिय'
            },

            form: {
                name: "अपनी वेबहुक URL दर्ज करें",
                button: "कॉलबैक URL जोड़ें",
                urlError: "कॉलबैक URL अनुपलब्ध है"
            },

            list: {
                title: "कॉलबैक वेबहुक सूची",
                subtitle: "कॉलबैक वेबहुक सूची",

                tableColumns: {
                    0: "ID",
                    1: "वेबहुक URL",
                    2: "स्थिति",
                    3: "क्रियाएँ"
                },

                delete: "हटाएँ"
            },

            copySecret: {
                title: "कॉलबैक सहेजा गया",
                info: "कृपया इस गुप्त कुंजी को कहीं सुरक्षित और पहुँचने योग्य स्थान पर सहेजें। सुरक्षा कारणों से, आप इसे फिर से देख नहीं सकते। यदि आप इस गुप्त कुंजी को खो देते हैं, तो आपको एक नया उत्पन्न करने की आवश्यकता होगी।",

                secret: "गुप्त कुंजी",
                callbackSecret: "लिक्पेड कॉलबैक गुप्त कुंजी",

                copy: {
                    btn: "कॉपी कॉलबैक सीक्रेट",
                    success: "कॉलबैक सीक्रेट क्लिपबोर्ड पर कॉपी किया गया था"
                }
            }
        },

        apiManagement: {
            list: "कुंजी सूची",
            link: "एपीआई दस्तावेज़",

            title: "एपीआई प्रबंधन",
            info: "एपीआई प्राइवेट कुंजी बनाने से तीसरी पार्टी साइट या ऐप्लिकेशन के माध्यम से लिकपेड पर बाजार और रियल-टाइम ट्रेडिंग सेवाओं तक पहुँच होती है।",

            keysList: {
                title: "कुंजी सूची",
                subtitle: "कुंजी सूची",

                tableColumns: {
                    0: "नाम",
                    1: "कुंजी",
                    2: "टोकन",
                    3: "सीक्रेट",
                    4: "कार्रवाइयाँ"
                },

                delete: "हटाएं"
            },

            addKeyForm: {
                label: "कृपया नयी एपीआई कुंजी के लिए लेबल (नाम) दर्ज करें",
                submit: "एपीआई कुंजी बनाएं",

                apiKey: "एपीआई कुंजी"
            },

            confirmCode: {
                title: "सुरक्षा सत्यापन",

                subtext: "अपने खाते को सुरक्षित बनाने के लिए, कृपया निम्नलिखित सत्यापन पूरा करें।",
                body: {
                    subtext: "अपने मेलबॉक्स के इनबॉक्स और स्पैम फोल्डर दोनों की जाँच करें",
                    h5: "ईमेल सत्यापन कोड",
                    placeholder: "ईमेल से पिन"
                },

                submit: "प्रस्तुत करें"
            },

            displayCopyKeyModal: {
                title: "API KEY जेनरेट हुआ",

                text: "कृपया इस सीक्रेट को कहीं सुरक्षित और अनुकूल स्थान पर सहेजें। सुरक्षा के कारण, आप इसे फिर से नहीं देख सकेंगे। अगर आप इस सीक्रेट को खो देते हैं, तो आपको एक नया बनाना होगा।",

                token: "टोकन",
                secret: "सीक्रेट",

                submit: "कोड कॉपी करें",

                copyText: "Liqpaid एपीआई कुंजी",

                copy: {
                    success: "कुंजी क्लिपबोर्ड पर कॉपी की गई थी",
                }
            },

            logout: {
                success: "लॉगआउट सफलतापूर्वक"
            },

            footer: {
                copyright: "द्वारा भुगतान सेवा"
            },
        },
    },

    invoice: {
        timerMessages: {
            1: "भुगतान की प्रतीक्षा में...",
            2: "इनवॉइस समाप्त हो गया"
        },

        payWith: "से भुगतान करें",
        invoice: "इनवॉइस",
        orderAmount: "आदेश राशि",
        alreadyPaid: "पहले से ही भुगतान किया हुआ",
        totalFee: "कुल शुल्क",
        due: "बकाया",
        description: 'Description',

        scanTab: {
            name: "स्कैन",
            openWallet: "वॉलेट में खोलें"
        },

        copyTab: {
            name: "कॉपी",
            info: {
                0: "अपनी भुगतान पूरा करने के लिए, कृपया नीचे दिए गए पते पर",
                1: "भेजें।"
            },
            copied: "कॉपी की गई",
            amount: "राशि",
            address: "पता",
            link: "भुगतान लिंक"
        },
        transactionSuccess: {
            header: "लेन-देन सफलतापूर्वक हुआ",
            text: "Your payment has been credited into this invoice"
        },

        expired: {
            header: "क्या हुआ था?",
            text: {
                0: "यह इनवॉइस समाप्त हो गया है। इनवॉइस केवल %s. ",
                1: "यदि आप फिर से आदेश के लिए भुगतान करना चाहते हैं तो आप वापस आ सकते हैं।",
                2: "यदि आपने भुगतान भेजने का प्रयास किया है, तो यह अभी तक नेटवर्क द्वारा स्वीकार नहीं किया गया है। हमें अभी तक आपके धन प्राप्त नहीं हुए हैं।"
            },
            minutes: 'минут',
            hours: 'часов',
            id: "इनवॉइस आईडी"
        },

        refund: {
            header: "संपर्क और रिफंड ईमेल",
            label: "कृपया नीचे एक ईमेल पता दें। यदि आपके भुगतान में कोई समस्या है तो हम इस पते पर आपसे संपर्क करेंगे।",
            errorLabel: "कृपया एक वैध ईमेल पता दर्ज करें",
            continue: "जारी रखें"
        },

        footer: "के द्वारा संचालित",
    },

    invoicePay: {
        form: {
            1: "कृपया अपना ईमेल दर्ज करें...",
            info: "भुगतान राशि",
            2: "सिक्का चुनें"
        },
        payWith: "से भुगतान करें"
    },

    serverError: "एक आंतरिक सर्वर त्रुटि हुई।",
    copyError: "पाठ कॉपी करने में त्रुटि हुई"
}
