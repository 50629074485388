import FormGroupInput from "./Inputs/formGroupInput.vue";

import DropDown from "./DropdownComponent.vue";
import PaperTable from "./PaperTable.vue";
import Button from "./Button.vue";

import CardTemplate from "./Cards/CardTemplate.vue";
import ChartCard from "./Cards/ChartCard.vue";

let components = {
  FormGroupInput,
  CardTemplate,
  ChartCard,
  PaperTable,
  DropDown
};

export default components;

export {
  FormGroupInput,
  CardTemplate,
  ChartCard,
  PaperTable,
  DropDown,
  Button
};
