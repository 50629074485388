import axios from 'axios';
import encrypt from './Encrypt';
import router from '@/router';
import AutoUpdater from './AutoUpdater';

const TK = 'token';
const getToken = () => localStorage.getItem(TK);

function onFullFilled(response) {
    // Update page after build
    AutoUpdater(response.headers['x-app-hash']);

    return response;
}

function compareNumbers(a, b) {
    return a - b;
}

const obj_sorter = (request) => {
    const ordered = {};
    let inOrdered = {};

    Object.keys(request).sort().forEach((key) => {
        ordered[key] = request[key];

        if (request[key] && typeof request[key] === 'object' && !Array.isArray(request[key])) {
            const requestKey = request[key];
            inOrdered = {};

            Object.keys(requestKey).sort().forEach((k) => {
                const requestKeyK = requestKey[k];

                if (requestKeyK && typeof requestKeyK === 'object' && !Array.isArray(request[key])) {
                    Object.keys(requestKeyK).sort(compareNumbers).forEach((i) => {
                        requestKey[i] = requestKeyK[i]
                    })
                }

                inOrdered[k] = requestKey[k]
            });

            ordered[key] = inOrdered
        }
    });

    return ordered
};

async function onRejected(err) {
    //console.log('onRejected', err)

    if (err.response && err.response.status === 500) {
        // {data:err.response.data, url:err.response.config.url, method:err.response.config.method}
        // vuex.commit('INTERNAL_ERROR', true)
    }

    if (err.response && err.response.status === 409) {
        console.log('Back-end maintenance...');
    }

    if (err.response && err.response.status === 401) {
        //this.$store.dispatch('doLogout');

        // Do logout
        await axios.get('/invoices/auth/logout', {
            headers: {
                'login-token': localStorage.getItem('token'),
                'X-Auth-Sign': localStorage.getItem('secret'),
            }
        });

        // Clear local storage
        const lang = localStorage.getItem('lang');
        localStorage.clear();
        localStorage.setItem('lang', lang);

        // Do redirect to home page
        router.push('/');
    }

    if (err.response && err.response.status === 402) {
        alert('402 error');
    }

    return Promise.reject(err.response);
}

function onRequestError(error) {
    return Promise.reject(error);
}

const configure = (axios) => {
    axios.defaults.baseURL = process.env.VUE_APP_API_PATH;

    axios.interceptors.request.use((config) => {
        const token = getToken();
        if (token && !config.url.includes('api/default')) {
            config.headers['login-token'] = token;
        }

        if (!config.data) {
            config.data = {};
        }

        // If POST OR PUT
        if (config.method === 'post' || config.method === 'put') {
            // Set timestamp for unique request_id
            const randomInt = (Math.floor(Math.random() * (999 - 100)) + 100);
            config.data.request_id = new Date().getTime().toString() + randomInt.toString();

            if (config.config && config.config.headers) {
                config.headers['X-Auth-Sign'] = config.config.headers['X-Auth-Sign'];
            } else {
                config.headers['X-Auth-Sign'] = encrypt(obj_sorter(config.data));
            }
        }

        return config;
    }, onRequestError);

    axios.interceptors.response.use(onFullFilled, onRejected);

    return axios;
};

export default configure(axios);
