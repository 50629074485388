import sha256 from "crypto-js/sha256";

const KS = 'secret'
const getSecret = () => localStorage.getItem(KS);

function makeString(object, result_string) {
    const values = Object.values(object);

    values.forEach((item) => {
        if ((item && item !== '') || item === 0) {
            if (item instanceof Object) {
                result_string += makeString(item, '');
            } else {
                result_string += item.toString();
            }
        }
    });

    return result_string;
}

const encrypt = (sorted) => {
    let ready_string = makeString(sorted, '');

    const str_end = getSecret();

    if (str_end) {
        ready_string += str_end;
    }

    return sha256(ready_string.toString()).toString();
};

export default encrypt;
