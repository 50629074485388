export default ((currentHash) => {
    if (!currentHash) {
        return;
    }

    currentHash = `${currentHash}`;

    // return;
    const urlParams = new URLSearchParams(window.location.search);


    // eslint-disable-next-line no-undef
    if (BUILD_HASH === undefined || `${BUILD_HASH}` !== currentHash) {
        if (urlParams.get('x-app-hash-target') === currentHash) {
            // eslint-disable-next-line no-undef
            console.error("Failed to update page. Target hash " + currentHash + " Current hash " + (BUILD_HASH || ''));
  
            return;
        }

        urlParams.set('x-app-hash-target', currentHash);
        window.location.search = urlParams;
        
        setTimeout(() => window.location.reload(), 2000)

        return;
    }

    // eslint-disable-next-line no-undef
    if (urlParams.get('x-app-hash-target') && BUILD_HASH === currentHash) {
        urlParams.delete('x-app-hash-target');
    
        const newUrl = urlParams.toString() ? '?' + urlParams.toString() : '';
        
        history.replaceState(null, null, window.location.pathname + newUrl);
    }
});