export default {
    header: {
        login: "Login",
        signUp: "Register",
        logout: "Logout"
    },

    login: {
        h2: "Login to account",
        placeholder: {
            email: "E-mail",
            password: "Password",
            code_email: "Enter code from email",
            code_google: "Enter code from Google Authenticator"
        },
        forgot: "Forgot password?",
        signUp: {
            text: "Not a member?",
            link: "Sign Up",
        },
        submit: "Login",

        validation: {
            req: "This field is required",
            email: "This field must be a valid email",
            password: "This field must be a valid password (a-z, A-Z, 0-9)",
            code: "This field must be a {l}-digit code"
        },
        errors: {
            check: "Check form field",
        }
    },

    twoFaLinkActivation: {
        title: "Two-Factor Authentication",
        text: "Please wait..."
    },

    register: {
        h2: "Create an account",
        placeholder: {
            email: "E-mail",
            phone: "Phone",
            password_repeat: "Repeat password",
            password: "Password"
        },
        login: {
            text: "Already a member?",
            link: "Login",
        },

        submit: "Create Account",

        validation: {
            req: "This field is required",
            email: "This field must be a valid email",
            phone: "This field must be a valid phone",
            password: "This field must be a valid password (a-z, A-Z, 0-9)",
            password_repeat: "Passwords must match"
        },

        confirmation: {
            h1: "Almost done",
            p: {
                0: "Please click on the activation link in your email",
                1: "to confirm registration",
            },
            link: "Go to Login"
        },

        errors: {
            check: "Check form field",
        }
    },

    home: {
        promoSection: {
            h1: "Bitcoin payment processing gateway and business crypto wallet",
            descLeft: {
                u: "Accept payments in Bitcoin",
                text: "Ethereum, USDT and other digital currencies with a leading crypto payment gateway."
            },
            descRight: "Receive, send, store, exchange, and pay in cryptocurrency with a security audited crypto wallet."
        },

        h2: "All crypto services for business in one place",

        servicesSection: {
            0: {
                title: "Payment gateway",
                desc: "Our Crypto Payment system helps any type of online business",
                u: "accept crypto payments",
                desc2: "and receive an equal amount in the national currency of their choice instantly or hold it and pay with crypto to their partners and affiliates."
            },
            1: {
                title: "Crypto SaaS solution",
                desc: "This is a cost-effective opportunity to start their own digital currency payment business and become a crypto payment system or cryptocurrency service for merchants under their own brand out of the box."
            },
            2: {
                title: "Exchange",
                title2: "OTC desk",
                desc: "Businesses can exchange BTC, ETH, USDT, XRP and 20+ other cryptocurrencies to dozens of national currencies at the best rates and with no hidden fees. Fiat on and off ramps supported.",
                desc2: "OTC is available for exchange operations over 1M EUR."
            },
            h3: "Get to know how our solutions help your business growth",
            3: {
                title: "Hot wallet (custody)",
                desc: "Cryptocurrency hot wallet system aimed at businesses whose model requires a scalable and secure environment for the management of digital asset (acceptance, storage, and withdrawal of digital assets to multiple addresses).",
            }
        },

        h3: {
            0: "Personal approach",
            1: "to Bitcoin adoption",
            manage: "Manage your digital assets in a security audited crypto wallet app.",
        },

        wallet: {
            title: "crypto wallet",
            desc: {
                0: "Receive, store and send 15+ cryptocurrencies on 5 different blockchains in the best crypto wallet. Exchange all supported digital assets to 16 fiat currencies at the best rates.",
                1: "Buy Bitcoin and another crypto with bank cards is coming."
            }
        },

    },

    dashboard: {
        sidebar: {
            title: "Merchant Dashboard",

            links: {
                dashboard: "Dashboard",
                profile: "Profile",
                questionnaire: "Questionnaire",
                templates: "Invoice Templates",
                invoices: "Invoices",
                addresses: "Addresses",
                wallets: "Wallets / Withdraw",
                withdrawRequests: "Withdraw Requests",
                transactions: "Transactions",
                callbacks: "Webhooks / Callbacks",
                api: "API Management",
                logout: "Logout",
            },
        },

        main: {
            cards: {
                totalAvailableBalance: {
                    title: "Total Available Balance",
                    footerText: "All time"
                },
                revenue: {
                    title: "Revenue",
                    footerLinks: {
                        0: "All time",
                        1: "Month",
                        2: "Week",
                        3: "Today",
                    }
                }
            }
        },

        profile: {
            title: "Profile",
            subtitle: "Profile",

            info: {
                title: "User Info",
                email: "Email"
            },

            lang: {
                title: "Language",
                change: "Change language"
            },

            expirations: {
                invoice: {
                    title: "Expirations",
                    change: "Invoice expiry",
                },

                invoiceAddress: {
                    title: "Invoice address expiry",
                    hours: "hours"
                },

                minutes: "minutes",

                info: "If your client accidentally pays after the Invoice is expired and if the exchange rate dropped then the payment will not be exchanged and you can refund your client. If the exchange rate grew then the payment will be exchanged.",

                save: "Save settings",

                error: "Invoice expiry must be lower or equals Invoice address expiry"
            },

            google_2fa: {
                validate_pin_code: "Error valid PIN code",
                or: 'or',
                label: "Google app authentication",
                modal_title_2enable: 'Enable Google Two Factor',
                modal_title_2disable: 'Disable Google Two Factor',
                label_info: "Each time you login to your account you will need to enter the code from your Google Authenticator app.",
                enable: "Enable 2FA",
                disable: "Disable 2FA",
                acc_token: "Account token (key)",
                email_pin: "Email confirmation code",
                google_pin: "Google confirmation code",

                google_code_set_success: "Google code set successfully",
                success_2factor_disabled: "2 factor verification successfully disabled",

                enable_text: "Get apps to get code",
                put_code_text: "Enter code from Google Authorization app here",
                step_scan_qr: "Scan the QR code",
                step_enter_token: "Enter the token below",
                step_expl_1a: "The first step is to download the Google Authenticator app for your Android or iOS device. If you need help getting started, please see",
                step_expl_1b: "Google's Support Page",
                step_expl_2a: "If you do not have access to the Android Market or App Store, there are other options for getting Google Authenticator:",
                step_expl_2b: "Android Download, ",
                step_expl_2c: "Google Chrome Plugin",
                step_expl_2d: "iTunes App Store.",
                step_expl_3: "After You get code paste it here and send to server.",
                about_token: "The token will not be shown again after 2FA is enabled. If you have multiple devices, add your account token to add of them before clicking enable. (Note: Your Account Token will change earch time reload your browser.)",

                checking_msg: {
                    success_email_verified: "Two-factor verification succeed",
                    success_google_verified: "Two-factor verification succeed",
                    success_2factor_disabled: "2 factor verification successfully disabled",
                    success_2factor_on: "to disable enter 2FA code",
                    fail_email_verified: "The code is incorrect. Please try again",
                    fail_google_verified: "The code is incorrect. Please try again"
                }
            },
        },

        questionnaire: {
            title: "Questionnaire",
            subtitle: "Input your company details",
        },

        routeNames: {
            DashboardPage: "Dashboard",
            ProfilePage: "Profile",
            QuestionnairePage: "Questionnaire",
            CustomerLegalForm: "Questionnaire",
            RepresentativeForm: "Questionnaire",
            IncorporationLegalForm: "Questionnaire",
            ResidenceLegalForm: "Questionnaire",
            GeneralInfoLegalForm: "Questionnaire",
            IndustryActivitiesForm: "Questionnaire",
            ShareholderNaturalForm: "Questionnaire",
            ShareholderLegalForm: "Questionnaire",
            UBOForm: "Questionnaire",
            LiqpaidBusinessForm: "Questionnaire",
            CustomerAMLSubject: "Questionnaire",
            InvoiceTemplatesList: "Invoice Templates List",
            InvoicesForm: "Invoices Form",
            InvoicesList: "Invoices List",
            InvoiceTemplatesCreateForm: "Invoice Templates Form",
            InvoiceTemplatesEditForm: "Invoice Templates Form",
            AddressesList: "Addresses List",
            WalletsList: "Wallets List",
            WithdrawRequest: "Withdraw Request",
            WithdrawRequestConfirm: "Withdrawal Request Confirmation",
            WithdrawTransactions: "Withdraw Transactions",
            TransactionsList: "Transactions List",
            ApiManagement: "Api Management",
            CallbacksList: "Callbacks List",
            CurrenciesFeesList: "Currencies Fees"
        },

        templates: {
            table: {
                0: "ID",
                1: "Template Name",
                2: "Currency",
                3: "Amount",
                4: "Status",
                5: "Actions",
            },

            statuses: {
                0: "Created",
                1: "Wait for deposit",
                2: "Canceled",
                3: "Full filled"
            },

            noRecords: "No Records",

            title: "Invoice Templates List",
            subtitle: "Invoice Templates List",

            createInvoiceTemplate: "Create Invoice Template",

            cardTemplate: {
                0: "Transactions",
                1: "Embed code",
                2: "Link",
                3: "Delete",
                edit: "Edit"
            },

            copy: {
                0: "Link for template invoice",
                1: "was copied to clipboard",
                error: "Error in copying text"
            },

            invoiceWidget: {
                title: "Payment",
                prodPurchase: "Product purchase",
                btnSuccess: "Go to payment",
                label: {
                    email: "Please enter your email",
                    choose: "Choose the coin to pay with"
                },
                amount: "Payment amount",
            },

            widgetModal: {
                title: "Invoice Widget Code",
                label: {
                    email: "Please enter your email",
                    choose: "Choose the coin to pay with"
                },
                amount: "Payment amount",
                copy: {
                    success: "Widget code was copied to clipboard",
                    error: "Error in copying text",
                }
            }
        },

        invoiceTemplatesForm: {
            title: "Create Invoice Template",

            label: {
                name: "Invoice Template Name",
                convert: "Convert payments to",
                price: "Price in",
                currency: "Receive payments in",
                amount: "Amount Requested"
            },

            placeholder: {
                name: "Template Name",
                price: "Price in",
                currency: "Accept all coins",
                amount: "Amount Requested"
            },

            success: {
                0: "Link for template invoice",
                1: "was copied to clipboard",
            },

            submit: "Save and Copy link to invoice"
        },

        invoicesList: {
            title: "Invoices List",
            subtitle: "Invoices List",

            table: {
                0: "ID",
                1: "Currency",
                2: "Invoice Amount",
                3: "Status",
                4: "Date Created",
                5: "Actions",
            },

            link: "Link",

            copy: {
                0: "Link for invoice",
                1: "was copied to clipboard",
            },

            createInvoice: "Create Invoice"
        },

        invoicesForm: {
            title: "Create Invoice",

            label: {
                currency: "Currency",
                amount: "Amount Requested"
            },

            placeholder: {
                currency: "Choose Currency",
                amount: "Amount Requested"
            },

            success: {
                0: "Invoice was created and link for invoice",
                1: "was copied to clipboard",
            },

            submit: "Save and Copy link to invoice"
        },

        addresses: {
            statuses: {
                0: "Disabled",
                1: "Active",
                2: "Deprecated"
            },
            list: {
                title: "Addresses List",
                subtitle: "Addresses List",
                create: "Create address",
                edit: "Edit",
                delete: "Delete",

                table: {
                    0: "ID",
                    1: "Name",
                    2: "Currency",
                    3: "Convert to",
                    4: "Minimum Deposit",
                    5: "Address",
                    6: "Status",
                    7: "Action"
                }
            },

            form: {
                title: "Create address",
                label: {
                    label: "Name",
                    currency: "Currency",
                    convert_to: "Convert To:",
                    network_type: "Network Type"
                },
                placeholder: {
                    label: "Name",
                    currency: "Currency",
                    convert_to: "Don't Convert",
                    network_type: "Network Type"
                },
                submit: "Save address"
            }
        },

        wallets: {
            title: "Wallet Balances List",
            subtitle: "Wallet Balances List",

            tableColumns: {
                0: "Currency",
                1: "Balance Total",
                2: "Balance Available",
                3: "Withdraw",
                4: "Swap"
            },

            withdraw: "Withdraw",
            swap: "Swap"
        },

        walletsRequest: {
            title: "Create Withdraw Request",
            maxAmount: 'Max Amount',
            label: {
                networkType: "Network type",
                address: "Address",
                amount: "Amount",
                iban: "IBAN",
                paymentTo: 'Payment To',
                company_name: "Company Name",
                name: "Name",
                country: "Country",
                state: 'State/Region',
                address_line: "Address Line",
                city: 'City',
                zip: 'Postal Code',
                contacts: "Contacts",
            },
            placeholder: {
                networkType: "Network type",
                address: "Address",
                amount: "Amount",
                iban: "IBAN",
                paymentTo: 'Payment To',
                company_name: "Company Name",
                name: "Name",
                country: "Country",
                state: 'State/Region',
                address_line: "Address Line",
                city: 'City',
                zip: 'Postal Code',
                contacts: "Contacts",
            },
            submit: "Withdraw",

            detailTitle: 'Withdrawal Detail',

            confirmCode: {
                title: "Withdrawal request confirmation",
                text: "Please confirm the withdrawal of",
                labelEmail: 'Enter the verification code from your email',
                labelEmailSub: 'The code is valid for 5 minutes',
                labelGoogle: "Enter the verification code from your Google Authenticator",
                placeholderEmail: 'Enter code from email',
                placeholderGoogle: "Enter code from Google Authenticator",
                buttonConfirm: "Confirm"
            },
        },

        walletsRequestsList: {
            title: "Withdraw Requests List",
            subtitle: "Withdraw Requests List",

            tableColumns: {
                0: "ID",
                1: "Amount",
                2: "Currency",
                3: "Commission",
                4: "Address / IBAN",
                5: "Status",
                6: "Date",
                7: "Network"
            },

            statuses: {
                0: "Created",
                1: "Sent",
                2: "Waiting",
                3: "Under Review",
                4: "Processing",
                5: "Confirmation",
                6: "Deleted",
                7: "Canceled",
                11: "Withdrawing",
                12: "Lock process withdraw"
            }
        },

        transactionsList: {
            title: "Transactions List",
            subtitle: "Transactions List",

            tableColumns: {
                id: "ID",
                txid: "Hash",
                invoice_id: "Invoice ID",
                direction: "Direction",
                amount_paid: "Amount Paid",
                fee: "Commission",
                amount_converted: "Converted To",
                convert_rate: "Convert Rate",
                item_name: "Item Name",
                address_name: "Address Name",
                address_deposit: "Address Deposit",
                user_email: "Customer Email",
                time_created: "Date",
                address_from: "Address From",
                action: "Action",
                note: "Comment",
                order_status: "Order Status",
            },
            noteModal: {
                title: 'Transaction Comment',
                submit: 'Save Note'
            }
        },

        invoices: {
            statuses: {
                0: "Created",
                1: "Wait for deposit",
                2: "Canceled",
                3: "Full filled",
                4: "Expired",
                5: "Address Expired",
                partiallyPaid: 'Partially Paid'
            }
        },

        callbacks: {
            statuses: {
                0: "Disabled",
                1: "Active"
            },
            form: {
                name: "Please enter your webhook url",
                button: "Add Callback Url",
                urlError: "Callback URL is missing"
            },

            list: {
                title: "Callback Webhooks List",
                subtitle: "Callback Webhooks List",

                tableColumns: {
                    0: "ID",
                    1: "Webhook URL",
                    2: "Status",
                    3: "Actions",
                },

                delete: "Delete"
            },

            copySecret: {
                title: "Callback saved",
                info: "Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again. If you lose this secret key, you'll need to generate a new one.",

                secret: "Secret",
                callbackSecret: "Liqpaid callback secret",

                copy: {
                    btn: "COPY CALLBACK SECRET",
                    success: "Callback secret was copied to clipboard",
                }
            }
        },

        apiManagement: {
            list: "Keys List",
            link: "Api Documentation",

            title: "API Management",
            info: "Creating an API private key provides access to markets and real-time trading services on LiqPaid via a third-party site or application.",

            keysList: {
                title: "Keys List",
                subtitle: "Keys List",

                tableColumns: {
                    0: "Name",
                    1: "Keys",
                    2: "Token",
                    3: "Secret",
                    4: "Actions"
                },

                delete: "Delete"
            },

            addKeyForm: {
                label: "Please enter the label (name) for the new API key",
                submit: "Create API KEY",

                apiKey: "Api Key"
            },

            confirmCode: {
                title: "Security verification",

                subtext: "To secure your account, please complete the following verification.",
                body: {
                    subtext: "Check both Inbox and Spam folders of your mailbox",
                    h5: "Email verification code",
                    placeholder: "PIN from email"
                },

                submit: "Submit"
            },

            displayCopyKeyModal: {
                title: "API KEY generated",

                text: "Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again. If you lose this secret key, you'll need to generate a new one.",

                token: "Token",
                secret: "Secret",

                submit: "COPY CODES",

                copyText: "Liqpaid api keys",

                copy: {
                    success: "Key was copied to clipboard",
                }
            }
        },

        logout: {
            success: "Success Logout"
        },

        footer: {
            copyright: "Payment Service by"
        },

        currenciesFees: {
            sidebar: "Currencies Fees",
            routeNames: "Currencies Fees",
            list: {
                title: "Currencies Fees List",
                subtitle: "Currencies Fees List",
                table: {
                    currency: 'Coin',
                    deposit_fee: 'Deposit Fee',
                    withdraw_fee: 'Withdraw Fee'
                }
            }
        }
    },

    invoice: {
        timerMessages: {
            1: "Awaiting payment...",
            2: "Invoice expired"
        },

        payWith: "Pay with",
        invoice: "Invoice",
        orderAmount: "Order Amount",
        alreadyPaid: "Already Paid",
        totalFee: "Total fee",
        due: "Due",
        description: 'Description',

        scanTab: {
            name: "Scan",
            openWallet: "Open in wallet"
        },

        copyTab: {
            name: "Copy",
            info: {
                0: "To complete your payment, please send",
                1: "to the address below."
            },
            copied: "Copied",
            amount: "Amount",
            address: "Address",
            link: "Payment link"
        },
        transactionSuccess: {
            header: "Transaction Successful",
            text: "Your payment has been credited into this invoice"
        },
        expired: {
            header: "What happened?",
            text: {
                0: "This invoice has expired. An invoice is only valid for %s.",
                1: "You can return if you would like to submit your payment again.",
                2: "If you tried to send a payment, it has not yet been accepted by the network. We have not yet received your funds."
            },
            minute: 'minute',
            fewMinutes: 'minutes',
            manyMinutes: 'minutes',
            hour: 'hour',
            fewHours: 'hours',
            manyHours: 'hours',
            second: 'second',
            fewSeconds: 'seconds',
            manySeconds: 'seconds',
            id: "Invoice ID"
        },

        refund: {
            header: "Contact & Refund Email",

            label: "Please provide an email address below. We’ll contact you at this address if there is an issue with your payment.",

            errorLabel: "Please enter a valid email address",
            continue: "Continue"
        },

        footer: "Powered by"
    },

    invoicePay: {
        form: {
            1: "Please enter your email...",
            info: "Payment amount",
            2: "Choose the coin"
        },

        payWith: "Pay with"
    },

    serverError: "An internal server error occurred",
    copyError: "Error in copying text",
    toast: {
        errorCode: {
            "343": "Too many wrong attempts. Please try again in {time}",
            "unknown": "An unknown error occurred. Please try your action again"
        }
    },
}
