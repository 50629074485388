import {Button, CardTemplate, DropDown, FormGroupInput} from "../views/dashboard/components";

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */
const GlobalComponents = {
    install(Vue) {
        Vue.component("fg-input", FormGroupInput);
        Vue.component("drop-down", DropDown);
        Vue.component("card-template", CardTemplate);
        Vue.component("p-button", Button);
    },
};

export default GlobalComponents;
