import axios from '../../utils/api';
//import i18n from '@/translation';

//const { t } = i18n.global;

export default {
    state: {
        list: [],
        template: {},
        pagination: {
            page: 0,
            totalCountPage: 0
        },
    },
    getters: {
        getInvoiceTemplatesList: (state) => state.list,
        getInvoiceTemplate: (state) => state.template,
    },
    mutations: {
        SET_TEMPLATES_LIST(state, data) {
            state.list = data;
        },
        SET_TEMPLATE_DATA(state, data) {
            state.template = data;
        }
    },
    actions: {
//        loadInvoiceTemplates(context) {
//
//        },
        loadInvoiceTemplateByID(context, id = null){
            // Do request
            return axios.get('/invoices/public/get-template', {params: {id: id}})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_TEMPLATE_DATA', data.invoice_template);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async createTemplate(context, data = {}) {
            // Do send data
            return await axios.post('/invoices/templates/create', data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async updateTemplate(context, data = {}) {
            // Do send data
            return await axios.post('/invoices/templates/update', data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async deleteTemplate(context, id = null){
            // Do send data
            return await axios.post('/invoices/templates/delete', {id})
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
    modules: {}
};
