import axios from '../../utils/api';
import {formatDate} from "@/utils/Formatter";

export default {
    state: {
        requestsList: [],
        requestsPagination: {
            page: 0,
            totalCountPage: 0
        },
        counterparties: []
    },
    getters: {
        getCounterpartiesList: (state) => state.counterparties,
        getWithdrawRequestsList: (state) => state.requestsList,
        getWithdrawRequestPagination: (state) => state.requestsPagination
    },
    mutations: {
        SET_REQUESTS_LIST(state, data) {
            state.requestsList = data;
        },
        SET_REQUESTS_PAGINATION(state, data) {
            state.requestsPagination = data;
        },
        SET_COUNTERPARTIES_LIST(state, data) {
            state.counterparties = data;
        }
    },
    actions: {
        /**
         * Get withdrawal requests list
         *
         * @param context
         * @param params
         */
        loadWithdrawRequests(context, params = {}) {
            // Do get data
            axios.get('/invoices/withdraw/list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        // Transform items on push to table data
                        let list = [];
                        for (const [key] of Object.entries(data.data.withdraw_requests.list)) {
                            const item = data.data.withdraw_requests.list[key];

                            list.push({
                                id: item.id,
                                iso3: item.iso3,
                                commission: item.commission,
                                amount: item.amount,
                                txid: item.txid,
                                to_address: item.to_address,
                                time_created: item.time_create,
                                time_created_converted: formatDate(item.time_create),
                                network_name: item.network_name,
                                network_type: item.network_type,
                                status: item.status,
                                statusLabel: item.statusLabel,
                                currency_type: item.currency_type
                            });
                        }

                        context.commit('SET_REQUESTS_LIST', list);
                        context.commit('SET_REQUESTS_PAGINATION', data.data.withdraw_requests.pagination);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         *
         * @param context
         * @param params
         */
        loadWithdrawContacts(context, currency_id = null) {
            // Do get data
            axios.get(`/invoices/fiat/list-bank-details?currency_id=${currency_id}`)
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_COUNTERPARTIES_LIST', data.data.bank_details_list);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         *
         * @param context
         * @param id
         * @returns {*}
         */
        getWithdrawRequestCrypto(context, id) {
            const params = { withdraw_request_id: id }

            // Do get data
            return axios.get('/invoices/withdraw/get', { params })
                .then((response) => {
                    if (response.data.status) {
                        return response.data.data.withdraw_request;
                    }

                    return null;
                })
                .catch(() => {
                    return null;
                });
        },

        /**
         *
         * @param context
         * @param id
         * @returns {*}
         */
        getWithdrawRequestFiat(context, id) {
            const params = { withdraw_request_id: id }

            // Do get data
            return axios.get('/invoices/fiat/withdraw-get', { params })
                .then((response) => {
                    if (response.data.status) {
                        return response.data.data;
                    }

                    return null;
                })
                .catch(() => {
                    return null;
                });
        },

        /**
         * @param context
         * @param params
         */
        createWithdrawRequestCrypto(context, params) {
            return axios.post('/invoices/withdraw', params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         * @param context
         * @param params
         */
        createWithdrawRequestFiat(context, params) {
            return axios.post('/invoices/fiat/withdraw', params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         *
         * @param context
         * @param params
         * @returns {*}
         */
        doWithdrawRequestFiatConfirm(context, params) {
            return axios.post('/invoices/fiat/withdraw-confirmation', params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         *
         * @param context
         * @param params
         * @returns {*}
         */
        doWithdrawRequestCryptoConfirm(context, params) {
            return axios.post('/invoices/withdraw/confirm-code', params)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        /**
         *
         * @param context
         * @param params
         * @returns {*}
         */
        doWithdrawRequestFiatCancel(context, params) {
            return axios.post('/invoices/fiat/cancel-withdraw', params)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error.data;
            });
        },

        /**
         *
         * @param context
         * @param params
         * @returns {*}
         */
        doWithdrawRequestCryptoCancel(context, params) {
            return axios.post('/invoices/withdraw/cancel', params)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error.data;
            });
        }
    },
    modules: {}
};
