<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import FieldDateMixin from "@/views/dashboard/components/mixins/FieldDateMixin";

export default {
    name: "paper-table",
    components: {
        VueDatePicker,
    },
    mixins: [
        FieldDateMixin
    ],
    props: {
        columns: Array,
        data: Array,
        type: {
            type: String,
            default: "striped",
        },
        title: {
            type: String,
            default: "",
        },
        subTitle: {
            type: String,
            default: "",
        }
    },
    emits: [
        'onClickSorting',
        'onChangeSearching'
    ],
    data: () => ({
        search: {},
        hasSearch: false
    }),
    computed: {
        tableClass() {
            return `table-${this.type}`;
        },
    },
    watch: {
        data() {
            const hasOwnProperty = Object.prototype.hasOwnProperty;
            this.hasSearch = this.columns.some(obj => hasOwnProperty.call(obj, 'search'));
        },
        columns(columns) {
            this.applySelectedValues(columns);
        }
    },
    created() {
        this.applySelectedValues(this.columns);
    },
    methods: {
        onSorting(field, direction) {
            this.$emit('onClickSorting', {field, direction});
        },
        onSearching(type = 'text', keys = {}) {
            const filter = JSON.parse(JSON.stringify(this.search));

            // Handle selected date type
            if (type === 'datePicker') {
                if (typeof filter[keys.field] === 'object') {
                    const range = [];

                    for (let i in filter[keys.field]) {
                        range.push(this.dateConvert(filter[keys.field][i], i, 'utc'));
                    }

                    if (typeof keys.range === 'string') {
                        filter[keys.range ?? keys.field] = range.length > 0 ? `${range[0]} - ${range[1]}` : '';
                    } else {
                        filter[keys.range[0]] = range.length > 0 ? range[0] : '';
                        filter[keys.range[1]] = range.length > 0 ? range[1] : '';
                    }
                } else {
                    const dateFrom = this.dateConvert(filter[keys.field], 0, 'utc');
                    const dateTo = this.dateConvert(filter[keys.field], 1, 'utc');

                    filter[keys.range ?? keys.field] = `${dateFrom} - ${dateTo}`;
                }

                if (keys.range) {
                    delete filter[keys.field];
                }
            }

            // Handle inputted date by mask
            if (type === 'dateMask') {
                const dateFrom = this.dateConvert(filter[keys.field], 0, 'utc');
                const dateTo = this.dateConvert(filter[keys.field], 1, 'utc');

                if (dateFrom && dateTo && keys.field !== '') {
                    if (typeof keys.range === 'string') {
                        filter[keys.range ?? keys.field] = `${dateFrom} - ${dateTo}`;
                    } else {
                        filter[keys.range[0]] = dateFrom;
                        filter[keys.range[1]] = dateTo;
                    }

                    if (keys.range) {
                        delete filter[keys.field];
                    }
                } else {
                    filter[keys.field] = '';

                    if (typeof keys.range === 'string') {
                        filter[keys.range] = '';
                    } else {
                        filter[keys.range[0]] = '';
                        filter[keys.range[1]] = '';
                    }
                }
            }

            // Send data to parent component
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => {
                this.$emit('onChangeSearching', this.splitKeys(filter));
            }, type === 'text' ? 500 : 0);
        },
        hasValue(item, column) {
            return item[column.toLowerCase()] !== 'undefined';
        },
        itemValue(item, column) {
            return item[column.toLowerCase()];
        },
        copyValue(value, column){
            navigator.clipboard.writeText(value)
                .then(() => {
                    this.$toast.success('The ' + column + ' was copied');
                })
                .catch(err => {
                    console.error(`${this.$t('copyError')}: `, err);
                });
        },
        openExplorer(link) {
            window.open(link);
        },
        getSelectValues(filter) {
            let convertedValues = [];

            if (Array.isArray(filter.values)) {
                convertedValues = [...filter.values];
            } else {
                for (let key in filter.values) {
                    convertedValues.push({
                        key: key,
                        label: filter.values[key]
                    });
                }
            }

            // Add first nulled item
            if (!filter.hideFirstNull || filter.hideFirstNull !== true) {
                convertedValues.unshift({
                    key: '',
                    label: 'All'
                });
            }

            // Return ready values
            return convertedValues;
        },
        applySelectedValues(columns) {
            for (let key in columns) {
                const column = columns[key];

                if ('search' in column && typeof column.search === 'object') {
                    if ('default' in column.search) {
                        this.search[column.search?.key ?? column.key] = column.search.default;
                    }
                }
            }
        },
        splitKeys(obj) {
            const newObj = {};
            for (const key in obj) {
                if (key.includes(',')) {
                    const keys = key.split(',');
                    const value = obj[key];
                    keys.forEach(newKey => {
                        newObj[newKey.trim()] = value;
                    });
                } else {
                    newObj[key] = obj[key];
                }
            }

            return newObj;
        },
        datePickerOpened() {
//            console.log('alertFn');
        },
        datePickerClosed() {
//            console.log('alertFn');
        }
    },
};
</script>

<template>
    <div v-if="$slots.tools" class="pt-3 pb-3">
        <slot name="tools" />
    </div>
    <div v-if="$slots.pagination" class="pagination-bar pt-3 pb-2">
        <slot name="pagination" />
    </div>
    <div class="table-responsive table-long">
        <table class="table" :class="tableClass">
            <thead>
            <tr>
                <slot name="columns">
                    <th v-for="(column, index) in columns" :key="index" :style="column.styleHead">
                        <div v-if="column.sort" class="sorting">
                            <span>{{ column.title }}</span>
                            <div class="chevrons">
                                <fa-icon
                                    icon="fa-solid fa-chevron-up"
                                    @click="onSorting(column.sort?.key ?? column.key, 'asc')"
                                />
                                <fa-icon
                                    icon="fa-solid fa-chevron-down"
                                    @click="onSorting(column.sort?.key ?? column.key, 'desc')"
                                />
                            </div>
                        </div>
                        <span v-else>{{ column.title }}</span>
                        <div v-if="column.minColumnWidth" :style="{'width' : column.minColumnWidth, height: 0}" />
                    </th>
                </slot>
            </tr>
            <tr v-if="hasSearch">
                <th v-for="(column, k) in columns" :key="k" :style="column.styleHead">
                    <select
                        v-if="column.search && column.search?.type === 'select'"
                        v-model="search[column.search?.key ?? column.key]"
                        class="form-control"
                        @change="onSearching(column.search.type)"
                    >
                        <option
                            v-for="(item, i) in getSelectValues(column.search)"
                            :key="i"
                            :value="item.key"
                        >{{ item.label }}
                        </option>
                    </select>
                    <VueDatePicker
                        v-else-if="column.search && column.search?.type === 'datePicker'"
                        v-model="search[column.key]"
                        model-auto
                        utc="preserve"
                        range
                        :enable-time-picker="false"
                        @open="datePickerOpened"
                        @closed="datePickerClosed"
                        @update:model-value="onSearching(column.search.type, {
                                field: column.key,
                                range: column.search.key ?? null
                            })"
                    />
                    <input
                        v-else-if="column.search && column.search?.type === 'dateMask'"
                        v-model="search[column.key]"
                        type="date"
                        class="form-control"
                        :placeholder="placeholder"
                        maxlength="10"
                        @input="onSearching(column.search.type, {
                                field: column.key,
                                range: column.search.key ?? null
                            })"
                    >
                    <input
                        v-else-if="column.search"
                        v-model="search[column.search.key ?? column.key]"
                        type="text"
                        class="form-control"
                        @input="onSearching(column.search?.type ?? 'text')"
                    />
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in data" :key="index">
                <slot :row="item" v-for="(column, index) in columns" :key="index">
                    <td v-if="column.cellTemplate && $slots[column.cellTemplate]" :style="column.styleCell">
                        <slot :name="column.cellTemplate" :item="item" :column="column" :value="itemValue(item, column.key)" />
                    </td>
                    <td v-else-if="hasValue(item, column.key) && column.key !== 'action' && column.key !== 'note'" :style="column.styleCell">
                        <template v-if="column.multiValue">
                            <div v-for="(value, i) in column.multiValue" :key="i">
                                <b>{{value.title}}:</b> {{ itemValue(item, value.key) }}
                            </div>
                        </template>
                        <template v-else-if="column.copyValue">
                            <p-button type="info" simple @click="copyValue(itemValue(item, column.key), column.key)" style="padding: 0; text-transform: none">{{ itemValue(item, column.key) }}</p-button>
                        </template>
                        <template v-else-if="column.urlLink && itemValue(item, column.key) !== '—'">
                            <p-button type="info" simple @click="openExplorer(itemValue(item, column.urlLink.key))" style="padding: 0; text-transform: none">{{ itemValue(item, column.key) }}</p-button>
                        </template>
                        <template v-else>{{ itemValue(item, column.key) }}</template>
                    </td>
                    <td v-if="$slots.actionCell && column.key === 'action'" :style="column.styleCell">
                        <slot name="actionCell" :item="item" />
                    </td>
                    <td v-if="$slots.noteCell && column.key === 'note'" :style="column.styleCell">
                        <slot name="noteCell" :item="item" />
                    </td>
                </slot>
            </tr>
            <tr v-if="data.length === 0">
                <td colspan="10" class="text-center">{{ $t('dashboard.templates.noRecords') }}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="pagination-bar pb-2" v-if="$slots.pagination">
        <slot name="pagination" />
    </div>
</template>

<style scoped>
.form-control {
    padding: 7px;
}

.sorting {
    display: flex;
}

.chevrons {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
}

.chevrons svg {
    cursor: pointer;
    height: 0.6em;
}
</style>
