/**
 *
 * @type {{methods: {showUserDetail(*): void}}}
 */
const FieldDateMixin = {
    data: () => ({
        dateMask: {
            completed: false
        },
        mask: '##.##.####', // Маска для ввода даты
    }),
    computed: {
        placeholder() {
            // Генерируем плейсхолдер с полной маской
            let masked = '';
            let maskIdx = 0;

            for (let i = 0; i < this.mask.length; i++) {
                const maskChar = this.mask[maskIdx];

                if (maskChar === '#') {
                    masked += '_';
                } else {
                    masked += maskChar;
                }

                maskIdx++;
            }

            return masked;
        },
    },
    directives: {
        mask: {
            mounted(el, binding) {
                const mask = binding.value;
                const digits = /\d/;

                function isAllowed(char) {
                    return digits.test(char) || char === '.';
                }

                function applyMask(value) {
                    let masked = '';
                    let maskIdx = 0;
                    let valIdx = 0;

                    while (maskIdx < mask.length && valIdx < value.length) {
                        const maskChar = mask[maskIdx];
                        const valChar = value[valIdx];

                        if (maskChar === '#') {
                            masked += isAllowed(valChar) ? valChar : '_';
                            valIdx++;
                        } else {
                            masked += maskChar;
                        }

                        maskIdx++;
                    }

                    return masked;
                }

                function update() {
                    const inputValue = el.value;
                    const rawValue = inputValue.replace(/\D/g, ''); // Удаляем все нечисловые символы

                    el.value = applyMask(rawValue);
                }

                el.addEventListener('input', update);
                el.addEventListener('paste', () => setTimeout(update, 0));
                el.addEventListener('change', update);

                update();
            },
        }
    },
    methods: {
        dateConvert(dateString, type, format = 'timestamp') {
            const parsedDate = this.parseDate(dateString);

            if (parsedDate === null) {
                return null;
            }

            const {year, month, day} = parsedDate;

            let date;

            if (parseInt(type, 10) === 0) {
                date = new Date(Date.UTC(year, month - 1, day, 0, 0, 0));
            } else {
                date = new Date(Date.UTC(year, month - 1, day, 23, 59, 59, 999));
            }

            if(format === 'utc') {
                return date.toISOString();
            }else{
                return date.getTime();
            }
        },
        parseDate(dateString) {
            const regexes = [
                /^(\d{4})[-./](\d{2})[-./](\d{2})[T\s](\d{2}):(\d{2}):(\d{2})(?:\.\d{3}Z)?$/, // формат yyyy-mm-ddTHH:MM:SS.sssZ
                /^(\d{4})[-./](\d{2})[-./](\d{2})$/,     // формат yyyy-mm-dd
                /^(\d{2})[-./](\d{2})[-./](\d{4})$/,     // формат dd-mm-yyyy
                /^(\d{2})[-./](\d{2})[-./](\d{4})$/,     // формат mm-dd-yyyy
                /^(\d{1,2})[-./](\d{1,2})[-./](\d{4})$/, // формат m-d-yyyy с однозначными месяцем и днем
                /^(\d{4})[-./](\d{1,2})[-./](\d{1,2})$/, // формат yyyy-m-d с однозначными месяцем и днем
                /^(\d{1,2})[-./](\d{4})[-./](\d{1,2})$/, // формат d-m-yyyy с однозначными месяцем и днем
            ];

            let year, month, day;

            for (const regex of regexes) {
                const match = dateString.match(regex);
                if (match) {
                    [year, month, day] = match.slice(1, 4).map(Number);
                    break;
                }
            }

            // If year, month или day undefined, then format даты неизвестен
            if (!year || !month || !day || year < 1000 || month < 1 || month > 12 || day < 1 || day > 31) {
                return null;
            }

            // If month or day < 10, then add "0"
            if (month < 10) month = "0" + month;
            if (day < 10) day = "0" + day;

            return {year, month, day};
        }
    }
}

export default FieldDateMixin;
