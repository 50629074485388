import axios from '../../utils/api';

export default {
    state: {
        tickers: {},
        tickerToUpdate: null,
    },
    getters: {
        getTickerToUpdate: (state) => state.tickerToUpdate,
        getCurrentTicker: (state) => (pair_name) => pair_name in state.tickers ? state.tickers[pair_name] : null,
    },
    mutations: {
        SET_TICKER(state, {pair_name, data}) {
            state.tickers[pair_name] = data;
        },
        SET_TICKER_TO_UPDATE(state, pair_name) {
            state.tickerToUpdate = pair_name;
        }
    },
    actions: {
        loadCurrentTickerData(context, pair) {
            pair = pair.replace('/', '');

            return axios({url: '/v2/public/ticker', params: {pair}})
                .then(r => {
                    if (r.status && r.data.status && r.data.data) {
                        const pair_name = r.data.data.main.iso3 + '/' + r.data.data.second.iso3;

                        context.commit('SET_TICKER', {pair_name, data: r.data.data});

                        return r.data;
                    }

                    return false;
                }).catch(() => false);
        },
    },
    modules: {}
};
