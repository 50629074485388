import axios from '../../utils/api';
import base62 from 'base62';
import {formatDate, formattedNumFromInt} from "@/utils/Formatter";

export default {
    state: {
        currentInvoiceId: null,
        currentInvoiceEncodedId: null,
        currentInvoiceData: null,
        list: [],
        pagination: {
            page: 0,
            totalCountPage: 0
        },
    },
    getters: {
        getCurrentInvoiceId: (state) => state.currentInvoiceId,
        getCurrentInvoiceEncodedId: (state) => state.currentInvoiceEncodedId,
        getCurrentInvoiceData: (state) => state.currentInvoiceData,
        getInvoicesList: (state) => state.list,
        getInvoiceListPagination: (state) => state.pagination
    },
    mutations: {
        SET_CURRENT_INVOICE_DATA(state, data) {
            state.currentInvoiceData = data;
        },
        SET_CURRENT_INVOICE(state, currentInvoiceId = null) {
            if (currentInvoiceId) {
                state.currentInvoiceId = currentInvoiceId;
                state.currentInvoiceEncodedId = base62.encode(currentInvoiceId);
            }

            if (!currentInvoiceId) {
                state.currentInvoiceData = null;
            }
        },
        SET_INVOICES_LIST(state, data) {
            state.list = data;
        },
        SET_INVOICES_PAGINATION(state, data) {
            state.pagination = data;
        },
    },
    actions: {
        setCurrencyInvoiceData(context, id) {
            axios({url: '/v2/public/get-invoice', params: {id}})
                .then(r => {
                    if (r.status && r.data.status && r.data.data) {
                        context.commit('SET_CURRENT_INVOICE_DATA', r.data.data);

                        return true;
                    }

                    context.commit('SET_CURRENT_INVOICE_DATA', null);
                    context.commit('SET_CURRENT_INVOICE', null);

                    return false;
                }).catch(() => false);
        },
        updateCurrencyInvoiceData(context) {
            if (!context.getters.getCurrentInvoiceData) return;

            return context.dispatch('setCurrencyInvoiceData', context.getters.getCurrentInvoiceId);
        },
        setCurrentInvoice(context, currentInvoiceId) {
            if (isNaN(currentInvoiceId)) {
                context.commit('SET_CURRENT_INVOICE', null);

                return;
            }

            context.commit('SET_CURRENT_INVOICE', currentInvoiceId);

            context.dispatch('setCurrencyInvoiceData', currentInvoiceId);
        },
        loadInvoices(context, params = {}) {
            // Do send data
            return axios.get('/invoices/index/get-list', {params})
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        const currenciesList = context.getters.getCurrenciesAllList;

                        // Transform items on push to table data
                        let list = [];
                        for (const [key] of Object.entries(data.data.list)) {
                            const item = data.data.list[key];

                            const currency_id = (item.currency_id ?? item.convert_to_currency_id);
                            const currency = currenciesList[currency_id] || { decimal: 8 };

                            list.push({
                                id: item.id,
                                currency: currency.iso3,
                                amount: formattedNumFromInt(item.amount_requested ?? item.amount_requested_in_currency, currency.decimal),
                                amount_received: item.amount_received,
                                status: item.status,
                                time_created: item.time_create,
                                time_created_formated: formatDate(item.time_create)
                            })
                        }

                        context.commit('SET_INVOICES_LIST', list);
                        context.commit('SET_INVOICES_PAGINATION', data.data.pagination);
                    }

                    return true;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    modules: {}
};
