import axios from '../../utils/api';
import i18n from '@/translation';

export default {
    state: {
        settings: {},
        profile: {},
        account_security: {
            google_auth: {
                secret: null,
                qr_code: null
            },
        },
        codeEmailSentTime: {
            minutes: 5,
            seconds: 0,
            intervalId: null,
            started: false
        }
    },
    getters: {
        getSettings: (state) => state.settings,
        getProfile: (state) => state.profile,
        getSendCodeModalData: (state) => state.account_security.google_auth,
        getCodeEmailSentTime: (state) => state.codeEmailSentTime,
    },
    mutations: {
        SET_MERCHANT_SETTINGS(state, data) {
            state.settings = data;
        },
        SET_MERCHANT_PROFILE(state, data) {
            state.profile = data;
        },
        SET_ACCOUNT_SECURITY(state, data){
            state.account_security = data;
        },
        /**
         * Reset timer
         * @param state
         */
        sendCodeEmailStopTimer(state) {
            state.codeEmailSentTime.minutes = 5;
            state.codeEmailSentTime.seconds = 0;
            clearInterval(state.codeEmailSentTime.intervalId);
            state.codeEmailSentTime.started = true;
        },
        /**
         * Start timer
         * @param state
         */
        sendCodeEmailStartTimer(state) {
            state.codeEmailSentTime.started = true;

            state.codeEmailSentTime.intervalId = setInterval(() => {
                state.codeEmailSentTime.seconds--;

                if (state.codeEmailSentTime.seconds < 0) {
                    state.codeEmailSentTime.minutes--;
                    state.codeEmailSentTime.seconds = 59;
                }

                if (state.codeEmailSentTime.minutes === 0 && state.codeEmailSentTime.seconds === 0) {
                    clearInterval(state.codeEmailSentTime.intervalId);
                    state.codeEmailSentTime.intervalId = null;
                    state.codeEmailSentTime.started = false;
                }
            }, 1000);
        }
    },
    actions: {
        loadSettings(context) {
            // Do get data
            return axios.get('/invoices/merchant/settings')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_MERCHANT_SETTINGS', data.data);

                        localStorage.setItem('lang', data.data.language)
                        i18n.global.locale = data.data.language
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        loadProfile(context) {
            // Do get merchant data
            return axios.get('/invoices/merchant')
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        context.commit('SET_MERCHANT_PROFILE', data.data);
                    }

                    return data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        submitProfileVerification(context, data = {}) {
            // Do send data
            return axios.post('/invoices/merchant/submit', data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async saveSetting(context, data = {}) {
            // Do send data
            return await axios.post('/invoices/merchant/settings', data)
                .then((response) => {
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        enableAccSecurity: (context, params) => new Promise((resolve) => {
            // Do send data
            axios.post('/invoices/merchant/update-security', params)
                .then((response) => {
                    if (response.status === 200 && response.data.status) {
                        context.commit('SET_ACCOUNT_SECURITY', response.data.data);

                        resolve('success');
                    }else{
                        resolve(response.data.data.errors);
                    }
                })
                .catch((error) => {
                    resolve(error);
                });
        }),
        sendCodeAccSecurity: (context, params) => new Promise((resolve) => {
            // Do send data
            axios.post('/invoices/merchant/update-security', params)
                .then((response) => {
                    if (response.status === 200 && response.data.status) {
                        resolve('success');
                    }else{
                        resolve(response.data.data.errors);
                    }
                })
                .catch((error) => {
                    resolve(error);
                });
        }),
    },
    modules: {}
};
