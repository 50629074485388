import {createStore} from 'vuex'
import invoices from './modules/invoices';
import ticker from './modules/ticker';
import wallets from "./modules/wallets";
import withdraw from "@/store/modules/withdraw";
import configs from "./modules/configs";
import apiKeys from "@/store/modules/api-keys";
import callbacks from "@/store/modules/callbacks";
import merchant from "@/store/modules/merchant";
import transactions from "@/store/modules/transactions";
import addresses from "@/store/modules/addresses";
import invoiceTemplates from "@/store/modules/invoice-templates";
import session from "@/store/modules/session";
import kyc from "@/store/modules/kyc";

export default createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        invoices,
        ticker,
        wallets,
        withdraw,
        configs,
        apiKeys,
        callbacks,
        merchant,
        transactions,
        addresses,
        invoiceTemplates,
        session,
        kyc
    }
})
