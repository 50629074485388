import axios from '../../utils/api';

export default {
    state: {
        balances: {},
        totals: {}
    },
    getters: {
        getCurrencyBalance: (state) => (ticker) => ticker in state.balances ? state.balances[ticker] : null,
        getWalletBalances: (state) => state.balances,
        getTotals: (state) => state.totals,
    },
    mutations: {
        SET_BALANCE(state, {ticker, data}) {
            state.balances[ticker] = data;
        },
        SET_BALANCES(state, data) {
            state.balances = data;
        },
        SET_TOTALS(state, data){
            state.totals = data;
        }
    },
    actions: {
        loadWalletBalances(context) {
            // Do get data
            axios.get('/invoices/merchant/balances-volume')
                .then((response) => {
                    const data = response.data;

                    if (!data.status) {
                        return false;
                    }

                    // Transform items on push to table data
                    let balances = {};
                    for (const [key] of Object.entries(data.data.balances.list)) {
                        const item = data.data.balances.list[key];

                        let balance = Number(item.balance);
                        let balance_available = Number(item.balance_available);

                        if(balances[item.currency.id]){
                            balance = balances[item.currency.id].balance + Number(item.balance);
                            balance_available = balances[item.currency.id].balance_available + Number(item.balance_available);
                        }

                        balances[item.currency.id] = {
                            currency_id: item.currency.id,
                            currency_name: item.currency.iso3,
                            decimal: item.currency.decimal,
                            balance,
                            balance_converted: item?.balance_f ?? Number(balance / Math.pow(10, 8)).toFixed(8),
                            balance_available,
                            balance_available_converted: item?.balance_available_f ??  Number(balance_available / Math.pow(10, 8)).toFixed(8)
                        };
                    }

                    context.commit('SET_BALANCES', balances);

                    context.commit('SET_TOTALS', {
                        total: data.data.balances.total,
                        total_with_currency_id: data.data.balances.total_with_currency_id,
                        pnl: data.data.balances.pnl,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        /**
         *             from_currency_id
         *             to_currency_id
         *             amount  (int)
         *
         * @param context
         * @param params
         */
        swapWalletCurrency(context, params) {
            return axios.post('/invoices/transactions/swap', params)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error.data;
            });
        },

    },
    modules: {}
};
