import {createRouter, createWebHistory} from 'vue-router'

export const routes = [
    {
        path: '/',
        children: [
            {
                path: '',
                component: () => import('../views/default/DefaultLayout'),
                children: [
                    {
                        path: '',
                        name: 'HomePage',
                        component: () => import('../views/default/pages/HomePage'),
                    },
                    {
                        path: '/auth/login',
                        name: 'LoginPage',
                        component: () => import('../views/default/pages/Auth/LoginPage.vue'),
                    },
                    {
                        path: '/auth/register',
                        name: 'RegisterPage',
                        component: () => import('../views/default/pages/Auth/RegistrationPage.vue'),
                    },
                    {
                        path: '/auth/reset-password',
                        name: 'ResetPasswordPage',
                        component: () => import('../views/default/pages/Auth/ResetPasswordPage.vue'),
                    },
                    {
                        path: '/password-recovery/:code',
                        name: 'PasswordRecoveryPage',
                        component: () => import('../views/default/pages/Auth/PasswordRecoveryPage.vue'),
                    },
                    {
                        path: '/auth/activation/:token',
                        name: 'ActivationPage',
                        component: () => import('../views/default/pages/Auth/ActivationPage.vue')
                    },
                    {
                        path: '/auth/google',
                        name: 'GoogleProvider',
                        component: () => import('../views/default/pages/Auth/GoogleProvider'),
                    },
                    {
                        path: "link-confirmation/:code",
                        name: "WithdrawConfirm",
                        component: () => import('../views/default/pages/WithdrawConfirm.vue'),
                        props: true
                    },
                    {
                        path: '/2fa-auth/:code',
                        name: 'TwoFaLinkActivationPage',
                        component: () => import('../views/default/pages/Auth/TwoFaLinkActivationPage.vue'),
                    },
                    {
                        path: '/policy/cookie',
                        name: 'CookiePolicyPage',
                        component: () => import('../views/default/pages/CookiePolicy.vue'),
                    },
                    {
                        path: '/policy/privacy',
                        name: 'PrivacyPolicy',
                        component: () => import('../views/default/pages/PrivacyPolicy.vue'),
                    },
                    {
                        path: '/policy/prohibited-activities',
                        name: 'ProhibitedActivities',
                        component: () => import('../views/default/pages/ProhibitedActivities.vue'),
                    },
                    {
                        path: '/policy/risk-disclosure-statement',
                        name: 'RiskDisclosureStatement',
                        component: () => import('../views/default/pages/RiskDisclosureStatement.vue'),
                    },
                    {
                        path: '/policy/aml-policy',
                        name: 'AmlPolicy',
                        component: () => import('../views/default/pages/AmlPolicy.vue'),
                    },
                    {
                        path: '/policy/aml-policy',
                        name: 'AmlPolicy',
                        component: () => import('../views/default/pages/AmlPolicy.vue'),
                    },
                    {
                        path: '/policy/complaints-policy',
                        name: 'ComplaintsPolicy',
                        component: () => import('../views/default/pages/ComplaintsPolicy.vue'),
                    },
                    {
                        path: '/policy/terms-of-use',
                        name: 'TermsOfUse',
                        component: () => import('../views/default/pages/TermsOfUse.vue'),
                    },
                    {
                        path: '/support',
                        name: 'SupportPage',
                        component: () => import('../views/default/pages/SupportPage.vue'),
                    },
                ]
            }
        ]
    },

    {
        path: '/',
        name: 'Dashboard',
        component: () => import('../views/dashboard/DashboardLayout'),
        children: [
            {
                path: "dashboard",
                name: "DashboardPage",
                component: () => import('../views/dashboard/pages/DashboardMain'),
            },
            {
                path: "dashboard/profile",
                name: "ProfilePage",
                component: () => import('../views/dashboard/pages/Profile/ProfilePage.vue'),
            },
            {
                path: "dashboard/questionnaire",
                component: () => import('../views/dashboard/pages/Questionnaire/QuestionnaireLayout.vue'),
                children: [
                    {
                        path: 'customer-legal',
                        props: true,
                        name: 'CustomerLegalForm',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/CustomerLegalForm.vue')
                    },
                    {
                        path: 'representative',
                        props: true,
                        name: 'RepresentativeForm',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/RepresentativeForm.vue')
                    },
                    {
                        path: 'incorporation-legal',
                        props: true,
                        name: 'IncorporationLegalForm',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/IncorporationLegalForm.vue')
                    },
                    {
                        path: 'residence-legal',
                        props: true,
                        name: 'ResidenceLegalForm',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/ResidenceLegalForm.vue')
                    },
                    {
                        path: 'general-info',
                        props: true,
                        name: 'GeneralInfoLegalForm',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/GeneralInfoLegalForm.vue')
                    },
                    // {
                    //     path: 'industry-activities',
                    //     props: true,
                    //     name: 'IndustryActivitiesForm',
                    //     component: () => import('../views/dashboard/pages/Questionnaire/pages/IndustryActivitiesForm.vue')
                    // },
                    {
                        path: 'shareholder-natural',
                        props: true,
                        name: 'ShareholderNaturalForm',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/ShareholderNaturalForm.vue')
                    },
                    {
                        path: 'shareholder-legal',
                        props: true,
                        name: 'ShareholderLegalForm',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/ShareholderLegalForm.vue')
                    },
                    {
                        path: 'ubo-form',
                        props: true,
                        name: 'UBOForm',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/UBOForm.vue')
                    },
                    {
                        path: 'liqpaid-business',
                        props: true,
                        name: 'LiqpaidBusinessForm',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/LiqpaidBusinessForm.vue')
                    },
                    {
                        path: 'customer-aml-subject',
                        props: true,
                        name: 'CustomerAMLSubject',
                        component: () => import('../views/dashboard/pages/Questionnaire/pages/CustomerAMLSubject.vue')
                    },
                ]
            },
            {
                path: "dashboard/invoices",
                name: "InvoicesList",
                component: () => import('../views/dashboard/pages/Invoices/ListPage'),
            },
            {
                path: "dashboard/invoices/create",
                name: "InvoicesForm",
                component: () => import('../views/dashboard/pages/Invoices/FormPage'),
            },
            {
                path: "dashboard/invoice-templates",
                name: "InvoiceTemplatesList",
                component: () => import('../views/dashboard/pages/InvoiceTemplates/ListPage'),
            },
            {
                path: "dashboard/invoice-templates/create",
                name: "InvoiceTemplatesCreateForm",
                component: () => import('../views/dashboard/pages/InvoiceTemplates/FormPage'),
            },
            {
                path: "dashboard/invoice-templates/edit/:template_id",
                name: "InvoiceTemplatesEditForm",
                component: () => import('../views/dashboard/pages/InvoiceTemplates/FormPage'),
                props: true
            },
            {
                path: "dashboard/wallets",
                name: "WalletsList",
                component: () => import('../views/dashboard/pages/Wallets/ListPage'),
            },
            {
                path: "dashboard/wallets/withdraw/:currency_type/confirm/:request_id",
                name: "WithdrawRequestConfirm",
                component: () => import('../views/dashboard/pages/Withdraw/WithdrawRequestConfirm.vue'),
                props: true
            },
            {
                path: "dashboard/wallets/withdraw/:currency",
                name: "WithdrawRequest",
                component: () => import('../views/dashboard/pages/Withdraw/WithdrawRequest.vue'),
                props: true
            },
            {
                path: "dashboard/wallets/transactions",
                name: "WithdrawTransactions",
                component: () => import('../views/dashboard/pages/Withdraw/WithdrawRequestList.vue'),
            },
            {
                path: "dashboard/transactions",
                name: "TransactionsList",
                component: () => import('../views/dashboard/pages/Transactions/TransactionsList.vue'),
                props: true
            },
            {
                path: "dashboard/api-management",
                name: "ApiManagement",
                component: () => import('../views/dashboard/pages/ApiManagement/ContainerPage.vue'),
            },
            {
                path: "dashboard/callbacks",
                name: "CallbacksList",
                component: () => import('../views/dashboard/pages/Callbacks/CallbacksPage.vue')
            },
            {
                path: "dashboard/addresses",
                name: "AddressesList",
                component: () => import('../views/dashboard/pages/Addresses/ListPage.vue')
            },
            {
                path: "dashboard/fees",
                name: "CurrenciesFeesList",
                component: () => import('../views/dashboard/pages/FeesPage.vue')
            },
        ]
    },

    {
        path: '/',
        name: 'Verification',
        component: () => import('../views/verification/DefaultLayout.vue'),
        children: [
            {
                path: '/verification/success',
                name: 'VerificationSuccess',
                component: () => import('../views/verification/pages/SuccessPage.vue')
            },
            {
                path: '/verification/fail',
                name: 'VerificationFail',
                component: () => import('../views/verification/pages/FailPage.vue')
            }
        ]
    },

    {
        path: '/',
        name: 'InvoiceMain',
        component: () => import('../views/invoice/InvoiceLayout.vue'),
        children: [
            {
                path: '/view-invoice/:id',
                props: true,
                name: 'ViewInvoice',
                component: () => import('../views/invoice/pages/Invoice/InvoiceData')
            },
            {
                path: '/pay-invoice/:data',
                props: true,
                name: 'PayInvoice',
                component: () => import('../views/invoice/pages/InvoicePay')
            }
        ]
    },

    {
        path: '/',
        name: 'ApiDocumentation',
        component: () => import('../views/docs/DocsLayout'),
        children: [
            {
                path: "/api-documentation",
                name: "ApiDocumentationMain",
                component: () => import('../views/docs/pages/HomePage.vue'),
            },
            {
                path: "/api-documentation/api-keys",
                name: "ApiKeysPage",
                component: () => import('../views/docs/pages/ApiKeysPage.vue'),
            },
            {
                path: "/api-documentation/authorization",
                name: "AuthorizationPage",
                component: () => import('../views/docs/pages/AuthorizationPage.vue'),
            },
            {
                path: "/api-documentation/callbacks",
                name: "CallbacksPage",
                component: () => import('../views/docs/pages/CallbacksPage.vue'),
            },
            {
                path: "/api-documentation/api-reference",
                name: "ApiReference",
                component: () => import('../views/docs/pages/ApiReferencePage.vue'),
            },
            {
                path: "/api-documentation/api-requests",
                name: "CreatingApiRequest",
                component: () => import('../views/docs/pages/CreatingApiRequest.vue'),
            },
            {
                path: "/api-documentation/invoice-templates",
                name: "InvoiceTemplatesPage",
                component: () => import('../views/docs/pages/InvoiceTemplatesPage.vue'),
            },
        ]
    }

    // { path: "*", component: NotFound },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

const TK = 'token';
const getToken = () => localStorage.getItem(TK);

router.beforeEach(async (to, from, next) => {
    if (to.path.includes('dashboard') && getToken() === null) {
        if (to.path.includes('api-management')) {
            localStorage.setItem('is-api-docs', JSON.stringify(true));
        }

        if (to.name !== 'LoginPage') {
            next({
                name: 'LoginPage',
                query: {
                    target: to.fullPath
                }
            });
        }
    }

    if ((to.path === '/' || to.path.includes('auth')) && getToken() !== null) {
        if (to.name !== 'DashboardPage' && to.name !== 'TwoFaLinkActivationPage') {
            next({
                name: 'DashboardPage'
            });
        }
    }

    next();
});

export default router;
