export default {
    header: {
        login: "Anmeldung",
        signUp: "Registrieren",
    },

    login: {
        h2: "Anmelden",
        placeholder: {
            email: "E-Mail",
            password: "Passwort",
            code_email: "Geben Sie den Code aus der E-Mail ein",
            code_google: "Geben Sie den Code von Google Authenticator ein"
        },
        forgot: "Passwort vergessen?",
        signUp: {
            text: "Noch kein Mitglied?",
            link: "Registrieren",
        },
        submit: "Anmelden",
        validation: {
            req: "Dieses Feld ist erforderlich",
            email: "Dieses Feld muss eine gültige E-Mail sein",
            password: "Dieses Feld muss ein gültiges Passwort sein (a-z, A-Z, 0-9)",
            code: "Dieses Feld muss ein 4-stelliger Code sein."
        },
        errors: {
            check: "Formularfeld überprüfen",
        }
    },

    twoFaLinkActivation: {
        title: "Zwei-Faktor-Authentifizierung",
        text: "Bitte warten..."
    },

    register: {
        h2: "Konto erstellen",
        placeholder: {
            email: "E-Mail",
            phone: "Telefon",
            password_repeat: "Passwort wiederholen",
            password: "Passwort"
        },
        login: {
            text: "Bereits Mitglied?",
            link: "Anmelden",
        },
        submit: "Konto erstellen",
        validation: {
            req: "Dieses Feld ist erforderlich",
            email: "Dieses Feld muss eine gültige E-Mail sein",
            phone: "Dieses Feld muss eine gültige Telefonnummer sein",
            password: "Dieses Feld muss ein gültiges Passwort sein (a-z, A-Z, 0-9)"
        },
        confirmation: {
            h1: "Fast fertig",
            p: {
                0: "Bitte klicken Sie auf den Aktivierungslink in Ihrer E-Mail",
                1: "um die Registrierung zu bestätigen",
            },
            link: "Zur Anmeldung"
        },
        errors: {
            check: "Formularfeld überprüfen",
        }
    },

    home: {
        promoSection: {
            h1: "Bitcoin Zahlungsabwicklungs-Gateway und Geschäfts-Krypto-Wallet",
            descLeft: {
                u: "Akzeptiere Zahlungen in Bitcoin",
                text: "Ethereum, USDT und andere digitale Währungen mit einem führenden Krypto-Zahlungs-Gateway."
            },
            descRight: "Empfangen, senden, speichern, austauschen und mit einer sicherheitsauditierten Krypto-Wallet bezahlen."
        },

        h2: "Alle Krypto-Dienste für Unternehmen an einem Ort",

        servicesSection: {
            0: {
                title: "Zahlungsgateway",
                desc: "Unser Krypto-Zahlungssystem hilft jeder Art von Online-Geschäft",
                u: "akzeptiere Krypto-Zahlungen",
                desc2: "und erhalte sofort einen gleichwertigen Betrag in der gewünschten nationalen Währung oder halte ihn und zahle mit Krypto an Partner und Affiliates."
            },
            1: {
                title: "Crypto SaaS-Lösung",
                desc: "Dies ist eine kostengünstige Möglichkeit, ihr eigenes digitales Währungszahlungsgeschäft zu starten und unter ihrem eigenen Brand ein Krypto-Zahlungssystem oder einen Kryptowährungsdienst für Händler aus der Kiste heraus zu werden."
            },
            2: {
                title: "Wechsel",
                title2: "OTC-Schreibtisch",
                desc: "Unternehmen können BTC, ETH, USDT, XRP und 20+ andere Kryptowährungen zu Dutzenden von nationalen Währungen zu den besten Kursen und ohne versteckte Gebühren tauschen. Fiat On- und Off-Ramps unterstützt.",
                desc2: "OTC steht für Exchange-Operationen über 1M EUR zur Verfügung."
            },
            h3: "Erfahren Sie, wie unsere Lösungen Ihr Geschäftswachstum unterstützen",
            3: {
                title: "Hot Wallet (Custody)",
                desc: "Kryptowährungs-Hot-Wallet-System, das auf Unternehmen zugeschnitten ist, deren Modell eine skalierbare und sichere Umgebung für die Verwaltung digitaler Assets (Annahme, Speicherung und Auszahlung digitaler Assets an mehrere Adressen) erfordert."
            }
        },

        h3: {
            0: "Personalisierter Ansatz",
            1: "für die Bitcoin-Adaption",
            manage: "Verwalten Sie Ihre digitalen Vermögenswerte in einer sicherheitsüberprüften Crypto Wallet-App."
        },

        wallet: {
            title: "Crypto-Wallet",
            desc: {
                0: "Erhalten, speichern und senden Sie 15+ Kryptowährungen auf 5 verschiedenen Blockchains in der besten Crypto Wallet. Tauschen Sie alle unterstützten digitalen Assets gegen 16 Fiat-Währungen zu den besten Konditionen.",
                1: "Kaufen Sie Bitcoin und eine andere Krypto mit Bankkarten ist im Anmarsch."
            }
        }
    },

    dashboard: {
        sidebar: {
            title: "Händler-Dashboard",

            links: {
                dashboard: "Dashboard",
                profile: "Profil",
                templates: "Rechnungsvorlagen",
                invoices: "Rechnungen",
                addresses: "Adressen",
                wallets: "Brieftaschen / Abheben",
                withdrawRequests: "Abhebeanfragen",
                transactions: "Transaktionen",
                callbacks: "Webhooks / Callbacks",
                api: "API-Verwaltung",
                logout: "Abmelden",
            },
        },

        main: {
            cards: {
                totalAvailableBalance: {
                    title: "Verfügbares Guthaben insgesamt",
                    footerText: "Gesamtzeitraum"
                },
                revenue: {
                    title: "Einnahmen",
                    footerLinks: {
                        0: "Gesamtzeitraum",
                        1: "Monat",
                        2: "Woche",
                        3: "Heute",
                    }
                }
            }
        },

        profile: {
            title: "Profil",
            subtitle: "Profil",

            info: {
                title: "Benutzerinfo",
                email: "E-Mail"
            },

            lang: {
                title: "Sprache",
                change: "Sprache ändern"
            },

            expirations: {
                invoice: {
                    title: "Ablaufdaten",
                    change: "Rechnungsablauf"
                },

                invoiceAddress: {
                    title: "Ablaufdatum der Rechnungsadresse",
                    hours: "Stunden"
                },

                minutes: "Minuten",

                info: "Wenn Ihr Kunde versehentlich nach Ablauf der Rechnung bezahlt und wenn der Wechselkurs gesunken ist, wird die Zahlung nicht umgetauscht und Sie können Ihrem Kunden eine Rückerstattung geben. Wenn der Wechselkurs gestiegen ist, wird die Zahlung umgetauscht.",

                save: "Einstellungen speichern",

                error: "Das Ablaufdatum der Rechnung muss kleiner oder gleich dem Ablaufdatum der Rechnungsadresse sein"
            }
        },

        routeNames: {
            dashboard: "Dashboard",
            ProfilePage: "Profil",
            InvoiceTemplatesList: "Rechnungsvorlagen Liste",
            InvoicesForm: "Rechnungsformular",
            InvoicesList: "Rechnungen Liste",
            InvoiceTemplatesCreateForm: "Rechnungsvorlagen-Formular",
            InvoiceTemplatesEditForm: "Rechnungsvorlagen-Formular",
            AddressesList: "Adressliste",
            WalletsList: "Brieftaschen Liste",
            WithdrawRequest: "Abhebeanfrage",
            WithdrawRequestConfirm: "Bestätigung der Auszahlungsanforderung",
            WithdrawTransactions: "Abhebetransaktionen",
            TransactionsList: "Transaktionen Liste",
            ApiManagement: "API-Verwaltung",
            CallbacksList: "Webhooks / Callbacks Liste",
        },

        templates: {
            table: {
                0: "ID",
                1: "Vorlagenname",
                2: "Währung",
                3: "Angeforderte Menge",
                4: "Status",
                5: "Aktionen",
            },

            statuses: {
                0: "Erstellt",
                1: "Warten auf Einzahlung",
                2: "Abgebrochen",
                3: "Vollständig erfüllt"
            },

            noRecords: "Keine Aufzeichnungen",

            title: "Rechnungsvorlagenliste",
            subtitle: "Rechnungsvorlagenliste",

            createInvoiceTemplate: "Rechnungsvorlage erstellen",

            cardTemplate: {
                0: "Transaktionen",
                1: "Einbetten-Code",
                2: "Link",
                3: "Löschen",
            },

            copy: {
                0: "Link für Vorlagenrechnung",
                1: "wurde in die Zwischenablage kopiert",
            },

            invoiceWidget: {
                title: "Zahlung",
                prodPurchase: "Produktkauf",
                btnSuccess: "Zur Zahlung gehen",
                label: {
                    email: "Bitte geben Sie Ihre E-Mail-Adresse ein",
                    choose: "Wählen Sie die Münze aus, mit der Sie bezahlen möchten"
                },
                amount: "Zahlungsbetrag"
            },

            widgetModal: {
                title: "Rechnungs-Widget-Code",

                copy: {
                    success: "Widget-Code wurde in die Zwischenablage kopiert",
                    error: "Fehler beim Kopieren des Textes"
                }
            }
        },

        invoiceTemplatesForm: {
            title: "Rechnungsvorlage erstellen",

            label: {
                name: "Name der Rechnungsvorlage",
                convert: "Zahlungen umwandeln in",
                price: "Preis in",
                currency: "Zahlungen erhalten in",
                amount: "Angeforderter Betrag"
            },

            placeholder: {
                name: "Name der Vorlage",
                price: "Preis in",
                currency: "Alle Münzen akzeptieren",
                amount: "Angeforderter Betrag"
            },

            success: {
                0: "Link zur Vorlagenrechnung",
                1: "wurde in die Zwischenablage kopiert",
            },

            submit: "Speichern und Link zur Rechnung kopieren"
        },

        invoicesList: {
            title: "Rechnungsliste",
            subtitle: "Rechnungsliste",

            table: {
                0: "ID",
                1: "Währung",
                2: "Rechnungsbetrag",
                3: "Status",
                4: "Datum erstellt",
                5: "Aktionen",
            },

            link: "Link",

            copy: {
                0: "Link zur Rechnung",
                1: "wurde in die Zwischenablage kopiert",
                error: "Fehler beim Kopieren des Textes"
            },

            createInvoice: "Rechnung erstellen"
        },

        invoicesForm: {
            title: "Rechnung erstellen",

            label: {
                currency: "Währung",
                amount: "Angeforderter Betrag"
            },

            placeholder: {
                currency: "Währung auswählen",
                amount: "Angeforderter Betrag"
            },

            success: {
                0: "Rechnung wurde erstellt und der Link zur Rechnung",
                1: "wurde in die Zwischenablage kopiert",
            },

            submit: "Speichern und Link zur Rechnung kopieren"
        },

        addresses: {
            statuses: {
                0: "Deaktiviert",
                1: "Aktiv",
                2: "Deprecated"
            },

            list: {
                title: "Adressliste",
                subtitle: "Adressliste",
                create: "Adresse erstellen",
                edit: "Bearbeiten",
                delete: "Löschen",

                table: {
                    0: "ID",
                    1: "Name",
                    2: "Währung",
                    3: "In umwandeln",
                    4: "Mindestaufladung",
                    5: "Adresse",
                    6: "Status",
                    7: "Aktion"
                },
            },

            form: {
                title: "Adresse erstellen",
                label: {
                    label: "Name",
                    currency: "Währung",
                    convert_to: "Umwandeln in:",
                    network_type: "Netzwerktyp"
                },
                placeholder: {
                    label: "Name",
                    currency: "Währung",
                    convert_to: "Konvertieren Sie nicht",
                    network_type: "Netzwerktyp"
                },
                submit: "Adresse speichern"
            }
        },

        wallets: {
            title: "Liste der Wallet-Guthaben",
            subtitle: "Liste der Wallet-Guthaben",
            tableColumns: {
                0: "Währung",
                1: "Gesamtsaldo",
                2: "Verfügbarer Saldo",
                3: "Abheben",
                4: "Swap"
            },
            withdraw: "Abheben",
            swap: "Swap"
        },

        walletsRequest: {
            title: "Auszahlungsanfrage erstellen",
            label: {
                networkType: "Netzwerktyp",
                address: "Adresse",
                amount: "Betrag",
                iban: "IBAN"
            },
            placeholder: {
                networkType: "Netzwerktyp",
                address: "Adresse",
                amount: "Betrag",
                iban: "IBAN"
            },
            submit: "Abheben",

            detailTitle: 'Withdrawal Detail',

            confirmCode: {
                title: "Bestätigung der Auszahlungsanforderung",
                text: "Bitte bestätigen Sie die Auszahlung",
                labelEmail: "Überprüfen Sie Ihre E-Mail und geben Sie den Code ein",
                labelEmailSub: 'The code is valid for 5 minutes',
                labelGoogle: "Geben Sie den Code mit Google Authenticator ein",
                placeholderEmail: 'Geben Sie den Code aus der Mail ein',
                placeholderGoogle: "Geben Sie den Code von Google Authenticator ein"
            }
        },

        walletsRequestsList: {
            title: "Liste der Auszahlungsanfragen",
            subtitle: "Liste der Auszahlungsanfragen",
            tableColumns: {
                0: "ID",
                1: "Betrag",
                2: "Währung",
                3: "Kommission",
                4: "Adresse",
                5: "Status",
                6: "Datum",
                7: "Network"
            },

            statuses: {
                0: "Erstellt",
                1: "Gesendet",
                2: "Warten",
                3: "In Überprüfung",
                4: "Verarbeitung",
                5: "Bestätigung",
                6: "Gelöscht",
                7: "Abgebrochen",
                11: "Wird ausgezahlt",
                12: "Auszahlung gesperrt"
            }
        },

        transactionsList: {
            title: "Liste der Transaktionen",
            subtitle: "Liste der Transaktionen",
            tableColumns: {
                id: "ID",
                txid: "Hash",
                invoice_id: "Rechnungs-ID",
                direction: "Direction",
                amount_paid: "Bezahlter Betrag",
                fee: "Kommission",
                amount_converted: "Konvertiert zu",
                convert_rate: "Umrechnungskurs",
                item_name: "Artikelname",
                address_name: "Adressname",
                address_deposit: "Adress-Depot",
                user_email: "Kunden-eMail",
                time_created: "Erstellungsdatum",
                address_from: "Versandadresse",
                action: "Aktion",
                note: "Comment"
            },
            noteModal: {
                title: 'Transaction Comment',
                submit: 'Save Note'
            }
        },

        invoices: {
            statuses: {
                0: "Erstellt",
                1: "Warten auf Einzahlung",
                2: "Abgelaufen",
                3: "Erfüllt",
                4: "Abgelaufen",
                5: "Adresse abgelaufen",
                partiallyPaid: 'Partially Paid'
            }
        },

        callbacks: {
            statuses: {
                0: 'Behinderte',
                1: 'Aktiv'
            },

            form: {
                name: "Bitte geben Sie Ihre Webhook-URL ein",
                button: "URL zum Callback hinzufügen",
                urlError: "Callback-URL fehlt"
            },

            list: {
                title: "Liste der Callback-Webhooks",
                subtitle: "Liste der Callback-Webhooks",

                tableColumns: {
                    0: "ID",
                    1: "Webhook-URL",
                    2: "Status",
                    3: "Aktionen",
                },

                delete: "Löschen"
            },

            copySecret: {
                title: "Callback gespeichert",
                info: "Bitte speichern Sie diesen Geheimschlüssel an einem sicheren und zugänglichen Ort. Aus Sicherheitsgründen können Sie ihn nicht erneut anzeigen. Wenn Sie diesen Geheimschlüssel verlieren, müssen Sie einen neuen generieren.",

                secret: "Geheimschlüssel",
                callbackSecret: "Liqpaid-Callback-Geheimschlüssel",

                copy: {
                    btn: "COPY CALLBACK SECRET",
                    success: "Der Callback-Geheimschlüssel wurde in die Zwischenablage kopiert",
                    error: "Fehler beim Kopieren des Textes",
                }
            }
        },

        apiManagement: {
            list: "Schlüsselliste",
            link: "API-Dokumentation",

            title: "API-Verwaltung",
            info: "Das Erstellen eines privaten API-Schlüssels bietet Zugriff auf Märkte und Echtzeit-Handelsdienste auf LiqPaid über eine Drittanbieter-Website oder -Anwendung.",

            keysList: {
                title: "Schlüsselliste",
                subtitle: "Schlüsselliste",

                tableColumns: {
                    0: "Name",
                    1: "Schlüssel",
                    2: "Token",
                    3: "Geheimnis",
                    4: "Aktionen"
                },

                delete: "Löschen"
            },

            addKeyForm: {
                label: "Geben Sie bitte das Label (Name) für den neuen API-Schlüssel ein",
                submit: "API-SCHLÜSSEL ERSTELLEN",

                apiKey: "API-Schlüssel"
            },

            confirmCode: {
                title: "Sicherheitsüberprüfung",

                subtext: "Um Ihr Konto zu sichern, führen Sie bitte die folgende Überprüfung durch.",
                body: {
                    subtext: "Überprüfen Sie sowohl den Posteingang als auch den Spam-Ordner Ihres Postfachs",
                    h5: "E-Mail-Bestätigungscode",
                    placeholder: "PIN aus E-Mail"
                },

                submit: "Einreichen"
            },

            displayCopyKeyModal: {
                title: "API-Schlüssel generiert",

                text: "Bitte speichern Sie diesen geheimen Schlüssel an einem sicheren und zugänglichen Ort. Aus Sicherheitsgründen können Sie ihn nicht erneut anzeigen. Wenn Sie diesen geheimen Schlüssel verlieren, müssen Sie einen neuen generieren.",

                token: "Token",
                secret: "Geheimnis",

                submit: "CODES KOPIEREN",

                copyText: "Liqpaid-API-Schlüssel",

                copy: {
                    success: "Schlüssel wurde in die Zwischenablage kopiert",
                    error: "Fehler beim Kopieren des Textes"
                }
            }
        },

        logout: {
            success: "Erfolgreich abgemeldet"
        },

        footer: {
            copyright: "Zahlungsservice von"
        },
    },


    invoice: {
        timerMessages: {
            1: "Zahlung ausstehend...",
            2: "Rechnung abgelaufen"
        },
        payWith: "Zahlen mit",
        invoice: "Rechnung",
        orderAmount: "Bestellbetrag",
        alreadyPaid: "Bereits gezahlt",
        totalFee: "Gesamtgebühr",
        due: "Fällig",
        description: 'Description',
        scanTab: {
            name: "Scan",
            openWallet: "In Wallet öffnen"
        },
        copyTab: {
            name: "Kopieren",
            info: {
                0: "Um die Zahlung abzuschließen, bitte senden Sie",
                1: "an die unten angegebene Adresse."
            },
            copied: "Kopiert",
            amount: "Betrag",
            address: "Adresse",
            link: "Zahlungslink"
        },
        transactionSuccess: {
            header: "Transaktion erfolgreich",
            text: "Your payment has been credited into this invoice"
        },
        expired: {
            header: "Was ist passiert?",
            text: {
                0: "Diese Rechnung ist abgelaufen. Eine Rechnung ist nur gültig für %s. ",
                1: "Sie können zurückkehren, wenn Sie Ihre Zahlung erneut senden möchten.",
                2: "Wenn Sie versucht haben, eine Zahlung zu senden, wurde sie noch nicht vom Netzwerk akzeptiert. Wir haben Ihre Zahlung noch nicht erhalten."
            },
            minutes: 'минут',
            hours: 'stunden',
            id: "Rechnungs-ID"
        },
        refund: {
            header: "Kontakt- und Rückerstattungs-E-Mail",
            label: "Bitte geben Sie unten eine E-Mail-Adresse an. Wir werden Sie unter dieser Adresse kontaktieren, falls es ein Problem mit Ihrer Zahlung gibt.",
            errorLabel: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
            continue: "Weiter"
        },
        footer: "Powered by"
    },

    invoicePay: {
        form: {
            1: "Bitte geben Sie Ihre E-Mail-Adresse ein...",
            info: "Zahlungsbetrag",
            2: "Wählen Sie die Münze"
        },
        payWith: "Bezahlen mit"
    },

    serverError: "Ein interner Serverfehler ist aufgetreten",
    copyError: "Fehler beim Kopieren des Textes"
}
